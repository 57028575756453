.blogsPage {
  // background-color: white;
  padding-bottom: 50px;
  @media screen and (max-width: 600px) {
    padding-bottom: 50px;
  }
  .container {
    max-width: 1200px !important;
  }
  .blogFirstSession {
    padding-top: 15px;
    display: flex;
    flex-wrap: wrap;
    .latestMain {
      .latest {
        height: 500px;
        background-image: url("https://www.cosmetify.com/media/images/collagen_skin_c.2e16d0ba.fill-1101x478-c100.format-webp.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 600px) {
          height: 200px;
        }
        .butt {
          padding: 10px;
          background-color: white;
          font-size: 22px;
          font-weight: 500;
          margin-bottom: 20px;
        }
        .badge {
          position: absolute;
          top: 20px;
          right: -5px;
          background-color: black;
          padding: 7px 10px;
          color: white;
          font-size: 12px;
        }
      }
    }
    .trendingAndEditor {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 0;
      .butt {
        padding: 10px;
        background-color: white;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 20px;
      }
      .badge {
        position: absolute;
        top: 20px;
        right: -5px;
        background-color: black;
        padding: 7px 10px;
        color: white;
        font-size: 12px;
      }
      .trending {
        height: 242.5px;
        background-image: url("https://www.cosmetify.com/media/images/lady_with_rosace.2e16d0ba.fill-551x237-c100.format-webp.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 600px) {
          height: 200px;
          margin-top: 10px;
        }
        .badge {
          background-color: #e6007e;
        }
      }
      .editor {
        height: 242.5px;
        background-image: url("https://www.cosmetify.com/media/images/Cosmetify_blog_h.2e16d0ba.fill-551x237-c100.format-webp.webp");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        overflow: hidden;
        @media screen and (max-width: 600px) {
          height: 200px;
          margin-top: 10px;
        }
        .badge {
          background-color: #006bd6;
        }
      }
    }
  }

  .topicsSession {
    margin-top: 20px;
    h2 {
      text-align: center;
      @media screen and (max-width: 600px) {
        margin-bottom: 0;
      }
    }
    .topicBadges {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding-left: 55px;
      padding-right: 55px;
      @media screen and (max-width: 600px) {
        padding: 0;
      }
      .tagButt {
        font-size: 14px;
        line-height: 15px;
        font-weight: 400;
        background-color: transparent;
        text-transform: none;
        text-align: center;
        border: 1px solid #e0e0e2;
        border-bottom: 2px solid #e0e0e2;
        margin: 0;
        margin-right: 8px;
        margin-top: 8px;
        border-radius: 4px;
        display: block;
        padding: 10px;
        &.active {
          background-color: #f37545;
          color: white;
        }
        &:hover {
          background-color: #f3764591;
          color: white;
        }
      }
    }
  }

  .blogPosting {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    
    h6 {
      font-weight: bold;
      &.beside {
        margin-top: 20px;
      }
    }
    .blogs {
      padding-left: 0;
      padding-right: 0;
      .blogsPosts {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-evenly;
        .blog {
          padding-left: 0;
          margin-top: 10px;
          width: 40%;
          @media screen and (max-width: 600px) {
            width: 100%;
          }
          .blogImage {
            height: 220px;
            width: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            .tag {
              padding: 3px 6px 3px 8px;
              position: absolute;
              top: 15px;
              right: 0;
              font-size: 12px;
              line-height: 1rem;
              font-weight: 400;
              border-radius: 4px;
              border-top-right-radius: 0;
              border-bottom-right-radius: 0;
              margin: 0;
              font-weight: 600;
              text-transform: uppercase;
              color: #fff;
              background-color: #f37545;
            }
          }
          .blogContent {
            .title {
              font-size: 16px;
              font-weight: bold;
              margin: 0;
              margin-top: 10px;
              @media screen and (max-width: 600px) {
                text-align: center;
              }
            }
            .by {
              text-align: center;
              color: #717171;
              font-size: 12px;
              display: block;
              margin: 0;
              margin-top: 5px;
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    .blogsRelated {
      padding-left: 0;
      padding-right: 0;
      h6 {
        padding-left: 10px;
      }
      .relatedMain {
        margin-top: 20px;
        padding-left: 10px;
        .relatedBlogMain + .relatedBlogMain {
          margin-top: 20px;
          width: 100%;
          display: inline-block;
        }
        .relatedBlog {
          display: flex;
          width: 100%;
          .relatedImage {
            width: 170px;
            height: 70px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
          }
          .relatedContent {
            padding: 10px;
            display: flex;
            align-items: center;
            a {
              color: black;
              &:hover {
                color: #f37545;
              }
            }
            p {
              margin: 0;
              font-weight: 600;
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }

    .socioIcons {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      margin-top: 20px;
      i {
        font-size: 25px;
      }
    }
  }
}

.blogDetailsMain {
  background-color: white;
  padding-bottom: 50px;
  h6 {
    font-family: "amiri-bold-italic";
    font-weight: bold;
    font-size: 18px;
    &.beside {
      margin-top: 20px;
    }
  }
  .tree {
    padding-top: 10px;
    font-size: 12px;
    color: gray;
  }
  .title {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 10px;
    @media screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  h5 {
    font-family: "raleway-light";
    font-weight: bold;
    font-size: 20px;
    &.beside {
      margin-top: 20px;
    }
  }

  .by {
    font-size: 16px;
    color: gray;
    margin: 0;
    margin-top: 10px;
  }
  .blogPosting {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .blog {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 0;
      padding-left: 0;
      @media screen and (max-width: 600px) {
        padding-right: 0;
      }
      img {
        width: 80%;
        &.mainImage{
          width: 100%;
        }
      }
      .blogDes {
        line-height: 22px;
        padding-top: 20px;
        padding-bottom: 20px;
        color: black;
        @media screen and (max-width: 600px) {
          font-size: 16px;
          padding-bottom: 00px;
          width: calc(100vw - 40px);
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: "amiri-italic";
          color: #e47b51;
          background-image: none;
          text-align: initial;
        }
        p {
          font-family: "raleway-medium";
          font-size: 17px;
          line-height: 30px;
          color: #48403F
        }
        p h6 {
          font-family: "amiri-regular"
        }
        li {
          font-family: "raleway-medium";
          font-size: 17px;
          line-height: 30px;
          color: #48403F
        }
        li p {
          font-family: "raleway-medium";
          font-size: 17px;
          line-height: 30px;
          color: #48403F
        }
        h6 a {
          font-family: 'Courier New', Courier, monospace
        }
        code {
          padding-top: 100px !important;
          font-family: "indie";
          font-size: 25px;
        }
      }
    }
  }
.blogProductContent h6 a {
  font-weight: 1800 !important;
}

.blogDes a {
  color: #e47b51;
}

.blogDes h5 {
  font-family: "amiri-regular" !important;
  font-size: 20px;
}

  .blogsRelated {
    padding-left: 0;
    padding-right: 0;
    @media screen and (max-width: 600px) {
      padding-bottom: 20px;
    }
    h6 {
      padding-left: 10px;
      @media screen and (max-width: 600px) {
        padding: 0;
      }
    }
    .relatedMain {
      margin-top: 20px;
      padding-left: 10px;
      .relatedBlogMain + .relatedBlogMain {
        margin-top: 20px;
        width: 100%;
        display: inline-block;
      }
      .relatedBlog {
        display: flex;
        width: 100%;
        .relatedImage {
          width: 170px;
          height: 70px;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .relatedContent {
          padding: 10px;
          display: flex;
          align-items: center;
          a {
            color: black;
            &:hover {
              color: #f37545;
            }
          }
          p {
            margin: 0;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            margin-bottom: 0;
          }
        }
      }
    }
  }

  .socioIcons {
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    margin-top: 20px;
    i {
      font-size: 25px;
    }
  }

  .blogProducts {
    .blogProduct + .blogProduct {
      margin-top: 30px;
    }
    p{
      text-align: justify !important;
    }
    .blogProduct {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: 600px) {
        // flex-direction: column-reverse;
        margin-bottom: 40px;
      }
      &.aliggRight {
        text-align: left;
        h6,
        p {
          // text-align: right;
        }
        button {
          // float: right;
          @media screen and (max-width: 600px) {
            float: initial;
          }
        }
      }
      .blogProductImage {
        width: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 10px;
          text-align: center;
          img {
            width: 60%;
            margin: auto;
          }
        }
      }
      .blogProductContent {
        width: 50%;
        @media screen and (max-width: 600px) {
          width: 100%;
        }
        h6 {
          font-size: 16px;
          font-weight: bold;
          color: black;
          @media screen and (max-width: 600px) {
            text-align: center;
          }
          a {
            color: black;
            justify-content: left;
            font-size: 17px;
            font-family: "raleway-medium";
            &:hover {
              color: #f37545;
            }
          }
        }
        p {
          margin: 0;
          color: black;
          margin-top: 10px;
          @media screen and (max-width: 600px) {
            text-align: justify;
          }
        }
        button {
          border-radius: 5px;
          background-color: #f37545;
          color: white;
          padding: 5px 20px;
          border: none;
          margin-top: 20px;
          transition: 0.3s;
          font-weight: 600;
          @media screen and (max-width: 600px) {
            margin-top: 0;
          }
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
  .subDes {
    font-size: 16px;
    color: black;
    margin-top: 20px;
  }

  .authorDiv {
    width: 100%;
    background-color: #f5f5f5;
    padding: 20px;
    .authorProfile {
      display: flex;
      align-items: center;
      .profileImage {
        width: 100px;
        height: 100px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 50%;
      }
      .profileContent {
        margin-left: 15px;
        p {
          margin: 0;
          font-weight: 600;
          color: black;
          font-size: 14px;
        }
        h6 {
          margin: 0;
          font-weight: 600;
          color: black;
          font-size: 18px;
        }
      }
    }
    .authorDescription {
      p {
        font-size: 16px;
        color: black;
        margin: 0;
        margin-top: 10px;
        line-height: 20px;
      }
    }
  }

  .socialButtonDiv {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      border: 1px solid #fff;
      position: relative;
      color: #fff;
      height: auto;
      text-align: left;
      padding: 10px 8px 10px 40px;
      text-decoration: none;
      font-weight: 600;
      font-size: 14px;
      cursor: pointer;
      width: 33%;
      i {
        margin-right: 10px;
        font-size: 18px;
      }
      &.facebook {
        background: #3b5999;
        &:hover {
          background: #2d4474;
        }
      }
      &.twitter {
        background: #55acee;
        &:hover {
          background: #2795e9;
        }
      }
      &.pinterest {
        background: #bd081c;
        &:hover {
          background: #8c0615;
        }
      }
    }
  }
}

.blogProductImage a {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.blogProductImage a::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.blogProductImage a:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}

.blog .blogImage {
  position: relative;
  height: 100%;
  width: 100%;
  display: inline-block;
  overflow: hidden;
}
.blog .blogImage::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
}
.blog .blogImage:hover::before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
