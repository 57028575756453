.quizzMain {
    #user-image {
        width: 600px;
    }

    .progress {
        width: 300px;
        height: 10px;
        margin: auto;
    }

    .progress-bar {
        background-color: #ff9676;
    }

    #user-data {
        color: black;
        font-weight: bolder;
        font-size: 17px;
    }

    /*================== Left Area END======================*/

    /*================== Main Screen seperation ======================*/

    .split {
        height: 100%;
        width: 50%;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow-x: hidden;
        padding-top: 20px;
    }

    .left {
        left: 0;
        background-color: #fbfbfb;
    }

    .right {
        right: 0;
        background-color: white;
    }

    .centered {
        background-position: center;
        height: 400px;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        .progress{
            margin: 0 !important;
            margin-left: auto !important;
            margin-right: auto !important;
        }
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // text-align: center;
    }

    .centered img {
        width: 150px;
        border-radius: 50%;
    }

    #right {
        width: 70%;
        // overflow-y: hidden !important;
    }

    #left {
        width: 30%;
    }

    @media only screen and (max-width: 768px) {
        #right {
            width: 100%;
            position: absolute;
            .row {
                margin: 0;
            }
            .col {
                padding: 0;
            }
        }

        #left {
            width: 0;
        }

        #btn-4 {
            width: 100%;
        }
        #btn-4 img {
            width: 100%;
        }
        #in-col-6 {
            display: flex;
            flex-direction: column;
            button {
                width: 100%;
            }
        }
    }

    /*==================Main Screen Seperation END======================*/

    /*==================Speech Bubble Questioanire======================*/

    .bubble-text-ques {
        padding: 12px 22px;
        color: white;
        text-align: center;
        background-color: #19354b;
        border-radius: 30px;
        height: max-content;
        margin-top: 10px;
        margin-left: 5px;
        font-size: 15px;
        @media only screen and (max-width: 768px) {
            margin-top: 0;
        }
    }

    .bubble-text-ans {
        padding: 10px;
        color: black;
        text-align: center;
        background-color: #dfdfdf;
        border-radius: 30px;
        margin-right: 300px;
        margin-top: 10px;
        text-transform: capitalize;
    }

    .robo {
        width: 60px;
        height: 60px;
        padding: 5px;
        background-color: white;
        border-radius: 50px;
        border-bottom-right-radius: 0px;
        box-shadow: 0px 0px 5px grey;
        margin-top: 0.5vw;
        margin-right: 10px;
        @media only screen and (max-width: 768px) {
            height: 50px;
            width: 50px;
        }
    }

    #in-col-1 {
        display: inline-flex;
        margin-right: 300px;
    }

    #in-col-5,
    #in-col-5two {
        display: none;
        margin-right: 300px;
    }

    #in-custom {
        display: none;
        margin-right: 300px;
    }

    #in-col-2,
    #in-col-2Retake,
    #in-col-3,
    #in-col-4,
    #in-col-7,
    #in-col-7two,
    #in-col-7three,
    #in-col-7four,
    #in-col-7five,
    #in-col-7six,
    #in-col-7seven,
    #in-col-7eight,
    #in-col-7nine,
    #in-col-7ten,
    #in-col-7eleven,
    #in-col-8,
    #in-col-1two,
    #in-col-1retake,
    #in-col-8two,
    #in-col-9,
    #in-col-10,
    #in-col-11,
    #in-col-11six,
    #in-col-img,
    #in-col-imgtwo {
        display: inline-flex;
        display: none;
        margin-right: 300px;
    }

    #in-col-imgtwo,
    #in-col-8two,
    #in-col-7two,
    #in-col-7three,
    #in-col-7four,
    #in-col-7five,
    #in-col-11six,
    #in-col-7six,
    #in-col-7seven,
    #in-col-7eight,
    #in-col-7nine,
    #in-col-7ten,
    #in-col-7eleven,
    #in-col-2Retake {
        @media only screen and (max-width: 768px) {
            margin: 0;
        }
    }

    #in-col-6 {
        display: inline-flex;
        display: none;
        margin-right: 100px;
    }

    @media only screen and (max-width: 1024px) {
        .bubble-text-ans {
            margin-right: 0px;
        }

        #in-custom,
        #in-col-1,
        #in-col-2,
        #in-col-3,
        #in-col-4,
        #in-col-5,
        #in-col-5two,
        #in-col-6,
        #in-col-7,
        #in-col-8,
        #in-col-9,
        #in-col-10,
        #in-col-11,
        #in-col-img {
            margin-right: 0px;
        }
        #in-col-5,
        #in-col-5two {
            margin-right: -15px;
        }
        #button-1,
        #button-2,
        #button-3,
        #button-4,
        #button-5,
        #button-6,
        #button-7,
        #button-8,
        #button-9,
        #button-10 {
            font-size: 14px;
            padding: 5px !important;
            margin-left: 5px !important;
        }
        #in-custom {
            margin-right: -15px;
        }
        #check1,
        #check2,
        #check3,
        #check4,
        #check5,
        #check6,
        #check7,
        #check8,
        #check9,
        #check10,
        #check11,
        #check12,
        #check13 {
            font-size: 14px;
            //   padding: 5px !important;
            margin-left: 5px !important;
        }
        #btn-2,
        #btn-7,
        #btn-8,
        #btn-9,
        #btn-10,
        #btn-11 {
            font-size: 14px;
            padding: 5px !important;
            margin-left: 5px !important;
            margin-top: 5px;
        }
        #btn-check1,
        #btn-check2,
        #btn-check3 {
            margin-top: 5px;
        }
        #btn-9 {
            width: 48%;
        }
        #btn-9 img {
            width: 100%;
        }
    }

    /*==================Speech Bubble Questioanire END======================*/

    /*==================Input Form======================*/
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .name {
        border-radius: 30px;
        border: none;
        padding-left: 10px;
        max-width: 150px;
        height: 40px;
        border: 2px solid #ff9676;
        outline: none;
    }

    #btn-1,
    #btn-3 {
        padding: 0px;
        background-color: transparent;
        font-size: 30px;
        border: none;
        padding: 10px;
        margin-top: -10px;
        color: grey;
        outline: none;
    }

    #btn-1:hover,
    #btn-3:hover {
        color: #ff9676;
    }

    #btn-2,
    #btn-7,
    #btn-8,
    #btn-9,
    #btn-10,
    #btn-11 {
        border-radius: 30px;
        border: none;
        padding: 7px;
        min-width: 100px;
        outline: none;
        margin-left: 5px;
        background-color: #dfdfdf !important;
        border: 1px solid #dfdfdf;
    }

    #btn-2:hover,
    #btn-7:hover,
    #btn-8:hover,
    #btn-9:hover,
    #btn-10:hover,
    #btn-11:hover {
        border: 1px solid #ff9676;
        background-color: white;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
        @media only screen and (max-width: 768px) {
            border-color: #dfdfdf;
            box-shadow: none;
        }
    }

    .selectedd {
        border: 1px solid #ff9676 !important;
        background-color: white !important;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
    }

    #type-image {
        width: 100px;
    }

    #level-image {
        width: 200px;
    }

    #skin-image {
        width: 150px;
        height: 250px;
    }
    #btn-4 {
        border-radius: 30px;
        border: none;
        padding: 10px;
        border: 2px solid transparent;
        outline: none;
        background-color: transparent;
        margin-left: 5px;
    }

    #btn-4:hover {
        border: 2px solid #ff9676;
    }

    #btn-check1,
    #btn-check2,
    #btn-check3 {
        border-radius: 30px;
        border: none;
        padding: 10px;
        border: 2px solid grey;
        outline: none;
        background-color: transparent;
        margin-left: 8px;
        height: 100px;
        min-width: 200px;
        width: 250px;
        color: black;
    }

    #button-1,
    #button-2,
    #button-3,
    #button-4,
    #button-5,
    #button-6,
    #button-7,
    #button-8,
    #button-9,
    #button-10 {
        border-radius: 30px;
        border: none;
        padding: 10px;
        border: 2px solid grey;
        outline: none;
        background-color: transparent;
        margin-left: 15px;
        margin-top: 10px;
        color: black;
    }

    .btn-custom {
        border-radius: 50px;
        border: none;
        padding: 5px;
        padding-left: 30px;
        padding-right: 30px;
        outline: none;
        background-color: #ff9676;
        color: white;
        margin-top: 20px;
        outline: none;
    }

    #select-all {
        background-color: transparent;
        border: none;
    }

    #select-all:hover {
        color: #ff9676;
        text-decoration: underline;
        cursor: pointer;
    }

    .submit {
        border-radius: 10px;
        border: none;
        padding: 10px;
        padding-left: 40px;
        padding-right: 40px;
        outline: none;
        background-color: #ff9676;
        margin-left: 5px;
        color: white;
        font-size: 20px;
        display: none;
        outline: none;
        margin-top: 20px;
    }

    .submit:hover {
        box-shadow: 5px 5px 6px grey;
    }

    .restart {
        border-radius: 10px;
        border: none;
        padding: 10px;
        padding-left: 40px;
        padding-right: 40px;
        outline: none;
        background-color: #ff9676;
        margin-left: 5px;
        color: white;
        font-size: 20px;
        display: none;
        margin-top: 20px;
    }

    .restart:hover {
        box-shadow: 5px 5px 6px grey;
    }

    /*==================Input Form END ======================*/

    /*==================Multi Select Button ======================*/

    /* =======================================================Checkbox========================================================== */

    #check1,
    #check2,
    #check3,
    #check4,
    #check5,
    #check6,
    #check7,
    #check8,
    #check9,
    #check10,
    #check11,
    #check12,
    #check13 {
        border-radius: 30px;
        border: none;
        padding: 0;
        border: 2px solid #e0e0e0;
        outline: none;
        background-color: #e0e0e0;
        // margin-left: 15px;
        margin-top: 10px;
        color: black;
        font-size: 15px;
        outline: none !important;
        box-shadow: none !important;
    }
    #checktwo1,
    #checktwo2,
    #checktwo3,
    #checktwo4,
    #checktwo5,
    #checktwo6,
    #checktwo7,
    #checktwo8,
    #checktwo9,
    #checktwo10,
    #checktwo11,
    #checktwo12,
    #checktwo13 {
        border-radius: 30px;
        border: none;
        padding: 10px;
        border: 2px solid #e0e0e0;
        outline: none;
        background-color: #e0e0e0;
        margin-left: 15px;
        margin-top: 10px;
        color: black;
        font-size: 15px;
    }

    .selected {
        background-color: white !important;
        box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28) !important;
        img {
            border: 2px solid #ff9676 !important;
        }
    }

    .btn-concern {
        border-radius: 50px;
        border: none;
        padding: 5px;
        padding-left: 30px;
        padding-right: 30px;
        outline: none;
        background-color: #ff9676;
        margin-left: 5px;
        color: white;
        margin-top: 20px;
        display: none;
    }

    #btn-concern3 {
        border-radius: 50px;
        border: none;
        padding: 5px;
        padding-left: 30px;
        padding-right: 30px;
        outline: none;
        background-color: #ff9676;
        margin-left: 5px;
        color: white;
        margin-top: 20px;
        display: none;
        margin: auto;
        margin-top: 20px;
    }

    /*==================Multi Select Button END ======================*/

    /*==================Multi Select Button-2 ======================*/

    .check {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .items-collect label.btn-default.active {
        background-color: #dfdfdf;
        color: white;
        outline: none;
    }
    .items-collect label.btn-default {
        border-radius: 20px;
        border: none;
        border: 1px solid grey;
        outline: none;
        background-color: transparent;
        font-size: 10px;
        width: 100px;
        padding-top: 5px;
        height: 30px;
        margin-top: -20px;
    }

    .skinConcernimages {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        .btn-primary {
            border-width: 0 !important;
            background-color: transparent !important;
        }
        img {
            width: 150px;
            // margin-right: 10px;
            border: 2px solid transparent;
            border-radius: 20px;
            // margin-top: 5px;
            margin: 0;
            cursor: pointer;
            @media only screen and (max-width: 768px) {
                width: 130px;
            }
            &:hover {
                //   border: 2px solid #FF9676;
            }
        }
    }

    .skinTone {
        flex-direction: column;
        margin-top: 10px;
        // width: 500px;
        margin-right: 0;
        .row {
            justify-content: end;
            width: 600px;
            margin-left: auto;
            @media only screen and (max-width: 768px) {
                width: 100%;
            }
        }
        .tooltipMain {
            margin: 0 !important;
        }
        .skinToneButton {
            padding: 0;
            border: 2px solid lightgray;
            margin: 0 0px;
            border-radius: 5px;
            border: none;
            cursor: pointer;
            background-color: transparent;
            img {
                width: 120px;
                @media only screen and (max-width: 768px) {
                    width: 80px;
                }
            }
        }
    }
    .btn-skin-tone-done {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
        .btn-skin-tone {
            border-radius: 50px;
            border: none;
            padding: 5px;
            padding-left: 30px;
            padding-right: 30px;
            outline: none;
            background-color: #ff9676;
            color: white;
        }
    }

    .sleepDiv {
        justify-content: center;
        #btn-11{
            background-color: white !important;
        }
        button {
            margin-right: 10px;
            background-color: transparent !important;
            border: none !important;
        }
        img {
            @media only screen and (max-width: 768px) {
                width: 150px;
            }
            width: 150px;
            border-radius: 30px;
        }
    }

    .waterDiv {
        justify-content: center;
        #btn-11{
            background-color: white !important;
        }
        button {
            margin-right: 10px;
            background-color: transparent !important;
            border: none !important;
        }
        img {
            @media only screen and (max-width: 768px) {
                width: 150px;
            }
            width: 150px;
            border-radius: 30px;
        }
    }
    /*==================Multi Select Button-2 END======================*/
}
.gifDiv {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    background-color: white;
    z-index: 99;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
        @media only screen and (max-width: 768px) {
            width: 80%;
        }
        width: 600px;
        // box-shadow: 3px 6px 32px 8px #00000094;
    }
    p {
        @media only screen and (max-width: 768px) {
            font-size: 12px;
        }
        font-size: 25px;
        margin-top: 40px;
        font-weight: bold;
    }
}
.pollutionDiv {
    #btn-9{
        background-color: white !important;
    }
    button {
        background-color: transparent !important;
    }
}

.brandsSelectDiv {
    margin-top: 10px;
    #btn-7{
        background-color: white !important;
    }
    button {
        margin-top: 10px;
        width: 200px;
        @media only screen and (max-width: 768px) {
            width: 48%;
            height: 350px;
        }
        background-color: white !important;
        display: flex;
        flex-direction: column;
        justify-content: start;
        padding: 10px !important;
        &:hover {
            border-color: transparent !important;
        }
        &.selectedd {
            &:hover {
                border-color: #ff9676 !important;
            }
        }
        &.noPrefer {
            font-size: 16px;
            text-align: center;
            // margin-top: 20px;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        h5 {
            font-size: 16px;
            text-align: center;
            margin-top: 20px;
            width: 100%;
            font-weight: bold;
        }
        .brandImages {
            margin-top: 20px;
            // display: flex;
            justify-content: space-evenly;
            flex-wrap: wrap;
            width: 100%;
            img {
                width: 60px;
                margin-right: 10px;
                margin-left: 10px;
                // height: auto;
            }
        }
    }
}

.tooltipMain {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    @media only screen and (max-width: 768px) {
        &.first {
            width: 50%;
            margin-left: 0px;
            #btn-4 {
                margin: 0;
            }
            #skin-image {
                width: 100% !important;
                height: 240px;
            }
        }
    }
    .button {
        color: #00000071;
        background-color: transparent;
        border: 1px solid #0000003d;
        border-radius: 5px;
        width: auto;
        height: 27px;
        padding: 0;
        margin: auto;
        transition: 0.3s;
        border: none;
        @media only screen and (max-width: 768px) {
            // display: none;
        }
        &:hover {
            color: #ff9676;
        }
    }
    .ant-tooltip-content {
        background-color: white !important;
    }
}
.ant-tooltip-inner {
    background-color: white !important;
    color: black !important;
    border-radius: 10px !important;
    padding: 10px !important;
    overflow: hidden;
}

.pollutionDiv {
    button {
        border: none !important;
    }
}

#in-col-imgtwo {
    margin-bottom: 10px;
}

.right-quiz {
    padding-bottom: 30px;
    @media only screen and (max-width: 600px) {
        overflow: hidden;
        padding-bottom: 50px;
    }
}
