h1,
h2,
h3,
h4 {
  font-family: "jenna Sue";
  font-weight: 700 !important;
}
h5,
h6,
p {
  font-family: "lato";
}
button {
  outline: none !important;
}
.banner1-text h5 {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 22px;
}
h2 {
  font-size: 47px !important;
  letter-spacing: 1px;
  padding-bottom: 10px !important;
  color: #19354b;
}
.quiz {
  font-family: "montserrat-medium" !important;
}
.quiz a {
  color: white !important;
  text-decoration: none;
}
@media screen and (max-width: 765px) {
  .banner1-text h5 {
    font-size: 18px;
  }
  h2 {
    font-size: 35px !important;
    padding-bottom: 20px !important;
  }
}
@font-face {
  font-family: jenna sue;
  src: url(./css/fonts/JennaSue.ttf);
}
@font-face {
  font-family: lato;
  src: url(./css/fonts/Lato-Regular.ttf);
}
.topBar {
  background: #19354b;
  @media screen and (min-width: 992px) {
    position: sticky;
    top: 72px;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
  z-index: 55;
  .navbar-nav {
    display: flex;
    flex-direction: row;
    .nav-item {
      color: white;
      &:hover {
        overflow: visible;
        .droppdownn2 {
          z-index: 999;
          opacity: 1;
        }
      }
      .nav-link {
        color: white !important;
        &:hover {
          border: none !important;
          color: #f47545 !important;
        }
      }
    }
  }
}
.topBar-con {
  display: flex;
  justify-content: space-between;
}
.topBar p,
.topBar a {
  padding: 6px 6px;
  margin-top: 7px;
  padding-bottom: 0;
  margin-bottom: 0;
  color: white;
  font-size: 14px;
  text-decoration: none;
}
.topBar a:hover {
  color: #f47545 !important;
  text-decoration: none;
}
.topBar i:hover {
  color: #f47545;
}
.topBar i {
  padding: 15px 0px;
  font-size: 13px;
}
@media screen and (max-width: 765px) {
  .topBar-con {
    flex-direction: column !important;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: space-evenly;
  }
}
.navbar {
  background-color: white;
  box-shadow: rgb(212, 211, 211) 1px 1px 1px 1px;
  padding-bottom: 0px !important;
  @media screen and (max-width: 765px) {
    .container {
      flex-direction: row-reverse !important;
    }
  }
}
.navbar-brand {
  @media screen and (max-width: 765px) {
    position: relative;
    right: 50%;
    transform: translate(50%, 0);
  }
}
.searchDrop {
  display: none;
}
.quiz {
  color: #333333;
  display: block;
  font-size: 14px;
  margin-top: 26px;
  line-height: 30px;
  font-weight: 400;
  position: relative;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-family: "Lato", sans-serif;
  background: #f47545;
  color: #fff !important;
  display: inline-block;
  font-family: Poppins;
  font-weight: 400;
  line-height: 34px;
  border-radius: 50px;
  padding: 0 15px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  box-shadow: 2px 1px 7px -2px #000;
  border: none !important;
  margin: 0;
  margin-right: 30px;
  // height: 30px;
  margin-bottom: 0;
  transition: all 0.3s ease 0s;
  display: flex;
  align-items: center;
}
.quiz:hover {
  margin-bottom: 10px;
}
.ipadview {
  display: none;
}

.quiz p {
  margin: 0;
  font-size: 14px;
  line-height: normal;
}
.navSvg {
  color: #19354b;
  font-size: 20px;
}
.carousel-item {
  position: relative;
}
.nav-link button:hover {
  margin-top: -5px;
}

@media screen and (min-width: 765px) {
  #section1text {
    margin-left: 70px;
  }
  #section1text h2 {
    font-size: 58px !important;
    margin-bottom: 5px;
  }

  #section1text p {
    font-family: "montserrat-medium";
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 765px) {
  .text h2 {
    font-size: 25px;
  }
  .text p {
    font-size: 15px;
    margin: 0;
  }
}
@media screen and (max-width: 765px) {
  .text h2 {
    margin-top: -20px;
    margin: 0;
  }
  .text p {
    margin: 0;
  }
  .carousel img {
    height: auto;
    width: 270% !important;
    position: relative !important;
    left: -30%;
  }
  .text {
    position: absolute;
    top: 45% !important;
    left: 0% !important;
    width: 100%;
    transform: translate(0, -50%);
    padding: 0px 20px;
  }
  .banner2 {
    background-position: center;
    background-size: cover !important;
  }
  .banner2 .banner2-text {
    height: 100% !important;
    margin: 0 !important;
    justify-content: space-between;
  }
  .team-img {
    overflow: hidden;
    width: 50px !important;
    height: 50px !important;
  }
  .team-img.active {
    overflow: hidden;
    width: 80px !important;
    height: 80px !important;
    opacity: 1;
  }
  .team-img img {
    width: 100% !important;
    position: initial !important;
  }
  .team {
    position: absolute !important;
  }
  .banner2 .carousel-indicators {
    height: 112px;
    top: 70%;
    margin: auto;
  }
  .banner2 .carousel-control-next,
  .banner2 .carousel-control-prev {
    height: 50px;
    top: 80% !important;
  }
}
.text {
  position: absolute;
  top: 30%;
  left: 10%;
}
.dil-serv {
  box-shadow: 0px 3px 5px 2px rgba(0, 0, 0, 0.2);
  margin-top: 60px;
  padding: 10px;
  border-radius: 10px;
  padding: 18px 0;
}
.lastdiv {
  border: none !important;
}
.dil-serv h6 {
  font-weight: bold;
  margin: 0;
  padding: 0;
}
.deliDiv h6 span {
  font-weight: normal;
}
.Delivery {
  display: flex;
  flex-wrap: wrap;
}
.deliDiv img {
  width: 80%;
  margin-right: 5px;
}
.Delivery .deliDiv {
  width: 25%;
  display: flex;
  flex-direction: row;
  text-align: left;
  align-items: flex-start;
  justify-content: center;
  padding: 15px 15px;
  border-right: rgba(0, 0, 0, 0.253) 1px solid;
}
.deliDiv svg {
  color: #f47545 !important;
  font-size: 30px;
  top: 5px;
}
.deliDiv div {
  padding: 0;
}
.deliDiv span {
  font-size: 13px;
}
@media screen and (max-width: 765px) {
  .Delivery .deliDiv {
    flex-direction: column;
    text-align: center;
  }
}
@media screen and (max-width: 765px) {
  .Delivery {
    width: auto;
    justify-content: space-between;
  }
  .deliDiv {
    width: 49% !important;
    border: none !important;
    align-items: center !important;
    padding: 10px !important;
    margin-top: 5px;
    border-radius: 10px;
    height: 130px;
    box-shadow: #f47545 0 0 15px -7px;
  }
  .deliDiv svg {
    font-size: 50px;
  }
  .dil-serv {
    border-radius: 0px;
    box-shadow: none;
  }
}
.work-con {
  // margin-top: 60px;
  @media screen and (max-width: 765px) {
    margin-top: 0px;
  }
  text-align: center;
}
.work-con h2 {
  margin-bottom: 0;
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
  width: auto;
  margin: auto;
}
.work2 {
  display: none;
  @media screen and (max-width: 765px) {
    display: block;
    .controlProduct {
      width: 100%;
    }
  }
}
.work {
  display: flex;
  justify-content: space-between;
  padding: 60px auto;
  &.work1 {
    @media screen and (max-width: 765px) {
      display: none;
    }
  }
  // &.work2 {
  // }

  &.work1 {
    @media screen and (min-width: 765px) {
      display: flex;
    }
  }
  &.work2 {
    @media screen and (min-width: 765px) {
      display: none;
    }
  }
}

.Work2Div {
  width: 80%;
  padding-top: 20px;
  margin: auto;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  height: 200px;
  margin-top: 10px;
  margin-bottom: 20px;
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 20px !important;
  img {
    width: 80px !important;
    position: initial !important;
  }
  h6 {
    text-align: center;
  }
  p {
    text-align: center;
  }
}

.work #workDiv1,
.work #workDiv2,
.work #workDiv3,
.work #workDiv4 {
  width: 22%;
  padding-top: 20px;
  padding: 20px;
  border-radius: 5px;
  transition: 0.3s;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  cursor: pointer;
}
.work-text {
  width: 100% !important;
}
@media screen and (max-width: 765px) {
  .work {
    flex-wrap: wrap;
  }
  .work #workDiv1,
  .work #workDiv2,
  .work #workDiv3,
  .work #workDiv4 {
    width: 50% !important;
  }
}

@media screen and (max-width: 765px) {
  .work {
    flex-direction: column;
  }
  .work #workDiv1,
  .work #workDiv2,
  .work #workDiv3,
  .work #workDiv4 {
    width: 70% !important;
    margin: auto;
    margin-top: 20px;
  }
  .work2 .slick-slide img {
    width: auto;
    height: auto;
    height: 85px !important;
    margin-bottom: 0 !important;
    width: auto !important;
  }
  .work h6 {
    margin: 0 !important;
    margin-top: 10px !important;
  }
  .steps {
    display: none !important;
  }
  .work-con h2 {
    margin-bottom: 40px;
    letter-spacing: 2px;
  }
  .mobileSilder {
    display: block !important;
  }
  .webSilder {
    display: none !important;
  }
  .brands .sliderimage {
    width: 40% !important;
  }
}
.mobileSilder {
  display: none;
}
.webSilder {
  display: block;
}
.sliderimage {
  height: auto;
  width: 50% !important;
  position: relative !important;
  left: 50% !important;
  transform: translate(-50%, 0px) !important;
}
#carousel-example4 {
  margin-top: 40px !important;
}
.mobileSilder .controlProduct {
  top: 30px !important;
}
.mobileSilder .controlProduct i {
  font-size: 20px !important;
}
.button-div button:hover {
  margin-top: -5px;
}
.button-div button {
  transition: 0.7s;
}
.banner {
  background-image: url(./Images/ychose-us.jpg);
  width: 100%;
  height: 800px;
  background-size: cover;
  margin-top: 60px;
  display: flex;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 800px !important;
  width: 100%;
  /* background-size: 100% 100% !important; */
  padding: 160px 0;
}

.about-banner {
  background-image: url(./Images/about/bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px;
  height: 440px;
}

.img-about {
  width: 300px;
  margin-left: 100px;
}

.about-bullets {
  font-weight: 600;
  margin-top: 70px !important;
  list-style: none;
  margin: 0;
  padding: 0;
}

.img-laptop {
  width: 600px;
}

.about-bullets li i {
  color: #f47545;
  margin-right: 3px;
}

.about-bullets li {
  margin-bottom: 5px;
}

#abt-bullets li i {
  font-weight: 60px !important;
}

@media screen and (max-width: 765px) {
  .about-bullets li {
    margin-bottom: 3px !important;
  }
  .about-bullets {
    margin-top: -10px;
  }

  .about-banner {
    background-image: url(./Images/about/bg-mobile.png) !important;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 660px;
    padding-bottom: 20px;
  }

  .img-about {
    margin-left: 0px !important;
    margin: 0px auto;
    width: 270px !important;
    margin-top: -20px;
  }
  .banner {
    margin-top: 80px !important;
    margin-top: 0;
    flex-direction: column !important;
    padding: 0 !important;
    overflow: hidden;
    align-items: center;
    flex-wrap: wrap;
    background-position: center;
    height: 645px !important;
    background-size: 171% 100%;
    background-image: none;
    background-color: #fcebd9;
  }

  .banner-about {
    margin-top: 80px !important;
    margin-top: 0;
    flex-direction: column !important;
    padding: 0 !important;
    overflow: hidden;
    align-items: center;
    flex-wrap: wrap;
    background-position: center;
    height: 645px !important;
    background-size: 171% 100%;
    background-image: none;
    background-color: #fcebd9;
  }
  .banner .video {
    width: 80% !important;
    margin-top: 0 !important;
    overflow: hidden;
    height: 200px;
    border-radius: 5px;
    margin-bottom: 0 !important;
    margin-top: 50px !important;
  }
  .banner .video img {
    width: auto;
    height: 290px;
    margin-top: -32px;
  }

  /*About*/

  .banner .whyWe {
    width: 90% !important;
    margin: 0;
    text-align: left;
    align-items: flex-start;
    height: 200px;
    display: flex;
    justify-content: start;
    margin-left: 0;
    margin: auto;
    margin-top: 0;
    margin-top: 50px;
  }
  .banner .whyWe h2 {
    font-size: 40px !important;
    margin-top: 0;
    background-size: contain;
    padding-bottom: 5px !important;
    margin-left: 10px;
    margin: auto;
    width: auto;
    margin-bottom: 20px;
    padding-bottom: 0 !important;
  }
  .banner .whyWe li {
    font-size: 16px !important;
    margin-top: 0;
    line-height: 26px;
  }
  .banner .whyWe ul {
    margin: 0;
  }
  .whyWe .butHeight {
    margin-top: 5px !important;
    width: 100%;
    margin: auto;
    margin-top: 20px !important;
  }
  .banner .whyWe .but {
    background: #f47545;
    color: #fff !important;
    font-family: Poppins;
    font-weight: 400;
    line-height: 34px;
    border-radius: 50px;
    padding: 0 16px;
    text-transform: capitalize;
    box-shadow: 2px 1px 7px -2px #000;
    border: none !important;
    margin: 0;
    margin-right: 30px;
    margin-bottom: 0;
    transition: all 0.3s ease 0s;
    display: flex;
    align-items: center;
    height: 30px !important;
    font-size: 16px !important;
    cursor: pointer;
    margin-top: 5px;
    width: 150px;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0px;
  }
  .whyWe {
    align-items: center;
  }

  .curate {
    margin-top: 10px !important;
  }
  .curate .cream {
    margin-top: 20px;
    height: auto !important;
  }
  .curate .cream img {
    margin: 0 !important;
  }
  .match {
    margin: 0 !important;
  }
  .curate .match p {
    text-align: center !important;
    padding-top: 10px !important;
  }
  .progressDashDiv {
    margin-left: -30px !important;
  }
  .progressDashDivMain .p {
    margin: auto !important;
  }
}

.banner div {
  width: 45%;
}
.video {
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-left: 50px;
  width: 38% !important;
}
.video img {
  float: right;
  width: 506px;
}
.whyWe {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 555px !important;
  margin-left: 60px;
}
.whyWe ul {
  padding: 0;
  margin-bottom: 9px;
}
.whyWe h2 {
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
  width: 240px;
  text-align: center;
  color: #19354b;
  margin-left: 45px;
}
.whyWe li {
  list-style: none !important;
  margin-top: 10px;
  font-size: 14px;
}
.whyWe li i {
  margin-right: 5px;
}
.banner .quiz {
  width: auto;
  margin-top: 12px;
}
.whyWe i {
  color: #f47545;
  padding: 0px 2px;
}
.whyWe .but {
  background: #f47545 none repeat scroll 0 0;
  color: #fff;
  display: inline-block;
  font-family: Poppins;
  font-size: 15px;
  font-weight: 400;
  line-height: 34px;
  border-radius: 50px;
  padding: 0 16px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
  box-shadow: 2px 1px 7px -2px #000;
  width: 130px;
  border: none;
  font-family: "lato";
  margin-top: 5px;
  transition: all 0.3s ease 0s;
  outline: none;
}
.whyWe .but:hover {
  margin-top: 0;
}
.whyWe .butHeight {
  height: 50px;
  // margin-top: 40px;
  width: 100%;
}

@media screen and (min-width: 1366px) {
  .banner {
    /* background-size: cover; */
    height: 800px;
  }
}

.mb-text {
  font-size: 30px !important;
  font-family: "koho-italic"
}

@media screen and (max-width: 765px) {
  .about-bullets {
    margin-top: 50px !important;
  }
  .mb-text {
    font-size: 30px !important;
    font-family: "koho-italic"
  }
  .img-laptop {
    width: 100%;
  }
  .banner {
    /* flex-direction: column; */
    height: 800px;
    width: auto;
    justify-content: center;
  }

  .video {
    width: 80% !important;
    text-align: center;
    margin: auto;
  }
  .video img {
    width: 350px;
    float: none !important;
  }
  .whyWe h2 {
    margin-top: 30px;
  }
  .whyWe {
    width: 100% !important;
    margin-top: 20px;
    text-align: center;
  }
  .whyWe ul {
    padding: 0;
  }
}
@media screen and (max-width: 765px) {
  .video img {
    width: 280px;
  }
}
.curate-con h2 {
  margin-top: 40px;
  text-align: center;
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
}
.curate {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 30px;
  align-items: center;
}
.cream {
  border: rgba(0, 0, 0, 0.1) 1px solid;
  width: 27%;
  border-radius: 5px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding: 20px 20px 10px 20px;
  width: 330px;
  height: auto;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  position: relative;
}
.cream img {
  margin: 0 !important;
  width: 100% !important;
}
.cream:hover {
  box-shadow: rgba(255, 192, 203, 0.486) 1px 1px 20px 3px;
}
.match {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.match .p,
.progressDashDivMain .p {
  font-size: 16px !important;
  letter-spacing: 1px;
  font-weight: 500;
  text-align: left;
  width: 80%;
  margin: auto;
  margin-top: 10px;
}
.match .dash {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.progressDashDivMain {
  overflow: hidden;
}
.progressDashDivMain .p {
  margin-left: 65px;
}
.progressDashDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: rotate(317deg);
  margin-left: 50px;
}
.progressDash1 {
  width: 2px;
  height: 7px;
  background-color: #dc6e45;
}
.progressDash {
  width: 2px;
  height: 15px;
  background-color: #dc6e45;
  margin-top: 10px;
}
.progressDott {
  width: 15px;
  height: 15px;
  background-color: #dc6e45;
  border-radius: 50%;
}
.match .dash .dashh {
  width: 2px;
  height: 15px;
  background-color: #dc6e45;
  margin-top: 5px;
}
.match .dash .dash1 {
  width: 2px;
  height: 7px;
  background-color: #dc6e45;
  margin-top: 5px;
}
.match .dash .dott2 {
  width: 15px;
  height: 15px;
  background-color: #dc6e45;
  border-radius: 50%;
}
.circle {
  border: 2px solid #dc6e45;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #f475450a;
}
.circle .dott {
  width: 15px;
  height: 15px;
  background-color: #dc6e45;
  border-radius: 50%;
  position: relative;
  top: -31px;
  right: 22px;
}
.circle p {
  font-size: 8px !important;
  margin: 0;
}
.circle h5 {
  color: black;
  margin: 0;
  font-size: 22px;
}
.progres-div {
  width: 25%;
  position: relative;
  /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
}
.progres-div-div {
  justify-content: space-between;
  align-items: center;
}
.progres-div label {
  font-size: 14px;
  color: #888888;
  /* margin-bottom: 0; */
  font-weight: 400;
  /* width: 100%; */
  /* text-align: left; */
}
.progres-div-div p {
  color: #888888 !important;
  /* margin: 0px;
    float:  left !important;
    text-align: left; */
}
.progress {
  height: 0.25rem !important;
  margin-bottom: 10px;
  /* display: flex;
    align-items: center;
    padding: 7px 3px;
    border-radius: 50px !important; 
    border: rgba(0, 0, 0, 0.253) 2px solid;
    background-color: transparent !important; */
}
.progress-bar {
  /* height: 10px;
    border-radius: 50px !important; 
    transition: 1s !important; */
}
.p1 {
  width: 0%;
  background-color: #f47545 !important;
}
.p2 {
  width: 0%;
  background-color: #f47545 !important;
}
.p3 {
  width: 0%;
  background-color: #f47545 !important;
}
.p4 {
  width: 0%;
  background-color: #f47545 !important;
}
.p5 {
  width: 0%;
  background-color: #f47545 !important;
}
.cream-img {
  margin: 15px auto;
  width: 100%;
  height: 245px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0px;
  @media screen and (max-width: 765px) {
    height: 200px;
    background-size: contain;
    background-position: center;
  }
}
.roundtick {
  border-radius: 50%;
  width: 35px !important;
  height: 35px;
  left: 4px;
  top: 4px;
  background-color: #f47545;
  position: absolute;
  justify-content: center;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 765px) {
  .roundtick {
    left: 5px;
    top: 5px;
  }
}
.innercircle {
  width: 21px;
  border-radius: 50%;
  height: 21px;
  border: 2px solid white;
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
}
.innercircle svg {
  color: white !important;

  font-size: 22px;
}
.curate p {
  color: #2b2b2b;
  text-align: center;
  font-family: lato;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}
.curate h6 {
  color: #f47545;
  text-align: center;
  font-family: sans-serif;
  font-weight: 700;
  font-size: 20px;
  padding: 20px 0 10px 0;
  margin-bottom: 0;
}
.cream ul {
  padding: 2px;
  display: flex;
  flex-direction: row-reverse;
  /* text-align: left; */
  justify-content: space-between;
  margin-bottom: 10px;
}
.cream li {
  list-style: none;
  font-size: 16px;
  font-family: lato;
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
}
.cream hr {
  width: 100%;
  margin: auto;
  margin-bottom: 1rem;
  margin-top: 1rem;
  border-width: 1px;
  border-color: #e5e5e5;
}
.cream i {
  padding: 2px;
  font-size: 14px;
  color: #000000a3;
}
/* .cream i:hover{
    color: rgb(255, 174, 0);
} */

@media screen and (max-width: 990px) {
  .cream,
  .match,
  .progres-div {
    width: 35%;
    padding: 10px;
  }
}
@media screen and (max-width: 765px) {
  .curate {
    flex-direction: row;
  }
  .cream {
    justify-content: space-between;
  }

  .cream img {
    width: 100px;
    margin: 5px !important;
  }
  .curate h6 {
    margin-top: 2px !important;
  }
  .cream ul,
  .cream i {
    padding: 0px;
    /* text-align: left; */
  }
  .smallbanner {
    flex-direction: column;
  }
  .smallbanner img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 765px) {
  .smallbanner {
    // display: none !important;
  }
  .curate {
    flex-direction: column;
  }
  .cream,
  .match,
  .progres-div {
    width: 100%;
    margin: 0 0;
  }
  #progressDivv {
    width: 100%;
    margin: 30px 0 !important;
    h3 {
      width: 100%;
    }
  }
  .cream {
    width: 90%;
    padding: 18px;
    box-shadow: rgba(255, 192, 203, 0.486) 1px 1px 20px 3px;
  }
  .progres-div {
    margin-bottom: 0px;
  }
  .cream img {
    width: 250px;
  }
  .cream li {
    display: inline;
  }
}
@media screen and (max-width: 765px) {
  .smallbanner img {
    height: auto;
    width: 100% !important;
    margin-top: 30px;
    position: relative;
    left: 0 !important;
  }
  .smallbanner {
    overflow: hidden;
    margin-top: 0 !important;
  }
  .smallBannerImageDiv1 {
    width: 100% !important;
    background-size: cover;
    background-position: center;
    height: 100px !important;
  }
  .smallBannerImageDiv2 {
    width: 100% !important;
    background-size: cover;
    background-position: center;
    height: 100px !important;
  }
}
@media screen and (max-width: 765px) {
  .smallbanner img {
    height: auto;
    width: 226%;
    margin-top: 30px;
    position: relative;
    left: -80px;
  }
  .smallbanner {
    overflow: hidden;
    margin-top: 0 !important;
  }
}
.smallbanner {
  display: flex;
  margin-top: 60px;
}
.smallBannerImageDiv1 {
  position: relative;
  width: 50%;
  background-image: url(./Images/smallbanner1.jpg);
  height: 199px;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
}
.smallBannerImageDiv2 {
  position: relative;
  width: 50%;
  background-image: url(./Images/smallbanner2.jpg);
  height: 199px;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
}
.smOne {
  width: 0%;
  height: 0%;
  border: 0px solid #ffffffde;
  transition: 0.8s;
  opacity: 0;
  position: absolute;
}

.smTwo {
  width: 100%;
  height: 100%;
  border: 60px solid #ffffffde;
  transition: 0.5s;
  opacity: 0;
  position: absolute;
}

.just-Ariv {
  margin-top: 60px;
  .slick-list {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  .product {
    height: 364px;
    .button-div button {
      margin-top: 5px;
      transition: 0.3s ease-in;
      &:hover {
        margin-top: 0;
      }
    }
  }
}
.just-Ariv h2 {
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
  text-align: center;
}
.product-div,
.product-div-1,
.product-div-2 {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-wrap: wrap;
  max-width: 1140px;
  margin: auto;
  .product {
    &:hover {
      .hoverDiv {
        opacity: 1 !important;
      }
    }
  }
}
.product {
  width: auto !important;
  padding: 10px;
  align-items: center;
  display: flex !important;
  flex-direction: column;
  // border: #4950572b 1px solid;
  border-radius: 5px;
  // background-color: white;
  cursor: pointer;
  transition: 0.7s;
  z-index: 3;
  outline: none;
  &:hover {
    .hoverDiv {
      opacity: 1 !important;
    }
  }
  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
.slick-slide {
  width: 257px !important;
  margin: 0px 9px !important;
  display: flex !important;
  text-align: center;
  outline: none !important;
}
.product:hover {
  box-shadow: rgba(255, 192, 203, 0.486) 1px 1px 20px 3px;
}
.slick-next:before,
.slick-prev:before {
  color: black;
}

.product-div img,
.product-div-1 img,
.product-div-2 img {
  width: 230px;
}
.progres-div .product,
.product-div-1 .product,
.product-div-2 .product {
  margin-top: 30px;
}
.product-txt {
  text-align: center;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}
.product-txt h6 {
  font-size: 15px;
  margin: 0;
  margin-top: 20px;
  font-family: "montserrat-medium";
}
@media only screen and (min-width: 700px) {
  .product-txt h6 {
    width: 220px;
    height: 50px;
  }
  .product-txt {
    height: 103px;
  }
}
@media only screen and (max-width: 700px) {
  .price {
    &.web {
      display: none !important;
    }
  }
  .product-txt h6 {
    // height: 100px;
    margin-top: 0;
  }
}
.product-txt i {
  // font-size: 16px;
  // color: rgb(255, 174, 0);
  font-style: normal;
}
.product-txt .price {
  margin: 0;
}
#recommendation-product-txt {
  height: 80px;
}

.product-txt p {
  margin: 0;
}

.product {
  .price {
    &.web {
      display: flex;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

.product-txt .price s,
.product-txt .price span {
  font-size: 18px;
}
.product-txt .price s {
  color: rgba(41, 39, 39, 0.45);
}
.product-txt .price span {
  color: #f47545;
  font-weight: 600;
}
.price {
  font-size: 10px;
  text-align: left;
}
.product hr {
  width: 100%;
  height: 1px;
  background: #0000001c;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
.button-div {
  padding: 6px;
  width: 80%;
  text-align: center;
  margin: auto;
}
.button-div button {
  background-color: #193548;
  font-size: 18px;
  padding: 3px 20px;
  border: none;
  border-radius: 50px;
  color: white;
}
.product-div-1,
.product-div-2 {
  display: none;
}

@media screen and (max-width: 765px) {
  .product-div-1 {
    display: block;
    display: flex;
    justify-content: space-evenly;
  }
  .product {
    width: 200px !important;
    @media screen and (max-width: 600px) {
      height: auto;
    }
    padding: 2px;
  }
  .product img,
  .product-txt,
  .button-div {
    margin: 0px !important;
    margin-top: 5px !important;
  }
  .product img {
    margin: 10px;
  }
  .product-div,
  .product-div-2 {
    display: none;
  }
}

@media screen and (max-width: 765px) {
  .product-div-1 {
    display: none;
  }
  .product-div-2 {
    display: block;
    display: flex;
    justify-content: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 600px) {
      .button-div button {
        transition: 0.7s;
        display: none;
      }
      .mob-btn {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
        .mob-wish {
          width: 50%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          button {
            background-color: transparent;
            color: #193548;
            border: none;
            padding: 0;
            svg {
              font-size: 15px;
            }
            p {
              font-size: 8px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .smallbanner {
    // display: none !important;
  }
}

.banner1 {
  //old

  margin-top: 60px;
  background-image: url(./Images/bannerbg1.jpg);
  height: 430px;
  background-size: contain;
  justify-content: center;
  background-repeat: no-repeat;
  text-align: center;
  display: flex;
  align-items: center;

  @media screen and (max-width: 600px) {
    background-size: cover;
    background-position: 6%;
  }
  @media screen and (max-width: 1024px) {
    background-size: cover;
  }

  //new

  // margin-top: 60px;
  // background-image: url(./Images/bannerbg1.webp);
  // height: 430px;
  // background-size: contain;
  // justify-content: center;
  // background-repeat: no-repeat;
  // text-align: center;
  // display: flex;
  // align-items: center;
}

.one {
  display: none;
}
.two img {
  display: none;
}
@media screen and (max-width: 765px) {
  .jusrAriveButtton .quiz {
    margin: 0 !important;
    padding: 0 10px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .one {
    display: block;
  }
  .two {
    display: block;
    margin: 0;
    background-position: center;
    height: 300px;
  }
  .two .banner1-text {
    //old
    width: 100%;
    margin: 0;
    justify-content: space-between;

    //new
    // width: 50%;
    // margin: 0;
    // justify-content: space-between;
  }
  .two .banner1-text .skinQuizBut {
    height: auto;
    margin-top: 30px;
  }
  .two .banner1-text .quiz {
    margin-right: 5px;
  }
  .two .banner1-text .shopNow {
    margin-right: 5px;
  }
  .two .banner1-text p {
    width: 100%;
    margin: auto;
    font-size: 16px;
    margin-bottom: 10px;
  }
  .two .banner1-text h5 {
    font-size: 18px !important;
    margin-bottom: 10px;
  }
  .two img {
    padding: 20px 0px;
    display: block;
    margin: auto;
  }
}

.banner1-text {
  //old
  width: 515px;

  //new
  // width: 50%;
}
.banner1 .quiz {
  box-shadow: none;
}
.shopNow {
  color: white;
  background-color: #19354b;
  padding: 5px 15px;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  justify-content: center;
}
.brands h2 {
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
  text-align: center;
  margin-top: 60px;
}
.brand-div {
  display: flex;
  justify-content: space-evenly;
  width: 95%;
  margin: auto;
  margin-top: 40px;
}
.brand-div img {
  width: 195px;
  transition: 0.7s;
  margin-right: 10px;
}
.brand-div img:hover {
  width: 210px;
  transition: 0.3s;
}
.brand-div2 {
  display: none;
}
@media screen and (max-width: 765px) {
  .brand-div {
    display: none;
  }
  .brand-div2 {
    display: block;
    display: flex;
    justify-content: space-evenly;
  }
  .brand-div2 img {
    width: 150px;
  }
}
.brandlogo h2 {
  text-align: center;
  padding: 20px;
}
/* Slider */
.brandlogo {
  margin: 63px auto;
}
.brandlogo h2 {
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
  text-align: center;
}
/* Slider */

@media screen and (max-width: 765px) {
  .products-carts {
    display: none !important;
  }
  .carousel-example2 {
    display: block !important;
  }
}
.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0 auto !important;
  padding: 20px 0px 20px 0px;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
  width: 80% !important;
  margin: 0px 23px;
}
.slick-slide img:hover {
  width: 84% !important;
  transition: 0.5s;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-prev {
  float: right;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.customer-logos {
  display: flex;
  padding: 0px 10px;
}
.product-slider .slick-slide {
  display: block;
  width: 257px !important;
  margin: 0px 9px !important;
  display: flex !important;
  text-align: center;
  outline: none;
}
.product-slider {
  height: 390px;
  display: flex;
  align-items: center;
}

.products-carts {
  display: flex;
  align-items: center;
  justify-content: center;
}
.products-carts .nextt,
.products-carts .previous {
  font-size: 34px;
  opacity: 0.7;
  padding-left: 0px 5px;
}
.jusrAriveButtton {
  justify-content: center;
  display: flex;
  margin: 0px !important;
}
.jusrAriveButtton a {
  color: white;
}
.jusrAriveButtton a:hover {
  color: white;
  text-decoration: none;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
  font-size: 0px !important;
}

.next {
  float: right;
}
.logos {
  display: flex;
  padding: 35px 0px;
}
.logbtn {
  align-items: center;
  display: flex;
  font-size: 35px;
  opacity: 0.4;
}
.slick-list {
  width: 1100px;
}
.banner2 {
  margin-top: 60px;
  // background-image: url(./Images/bannerbgtesti.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  height: 430px;
}
.banner2-text {
  width: 500px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 60%;
  position: relative;
  margin-top: 30px;
}
.image_with_slider {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 90%;
  top: 40%;
  left: 5%;
}
.top-16 i {
  color: #cacaca !important;
  font-size: 2.3em;
  text-shadow: none;
}
.banner2-text h1 {
  color: #f47545;
  font-size: 50px;
  position: relative;
  font-family: lato;
  line-height: 0;
  /*top: -18%;*/
}
.banner2-text .olie {
  color: #f47545;
  display: inline-block;
  margin-top: 15px;
  font-weight: 500;
  font-size: 18px;
}
.banner2-text i {
  color: rgb(255, 174, 0);
}
.banner2-text p {
  font-size: 16px;
}
.top-16 {
  top: 190px !important;
}
.big {
  width: 100px !important;
  height: 100px !important;
}
.light {
  opacity: 0.4;
  transition: 0.6s;
}

.team {
  display: flex;
  justify-content: space-evenly;
  width: 300px;
}
.team img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}
.team-img {
  border-radius: 50%;
  border: #f47545 2px solid;
  width: 60px;
  height: 60px;
  cursor: pointer;
}
.big {
  width: 80px !important;
  height: 80px;
}
.team1 {
  display: none;
}
.blog {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  text-align: justify;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}
.blog h2 {
  background-image: url(./Images/underline.png);
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 12px !important;
  text-align: center;
}
.blog-div {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
  .seeMore{
    color: black;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    margin-left: 30px;
    margin-right: -80px;
    &:hover{
      color: #f37545;
    }
  }
  .seeMore2{
    color: black;
    cursor: pointer;
    font-size: 13px;
    font-weight: 500;
    margin-top: 30px;
    &:hover{
      color: #f37545;
    }
  }
}
.blogCard {
  // border: 1px solid rgba(0, 0, 0, 0.123);
  width: auto;
  border-radius: 5px;
  margin: 0 10px;
  width: 271px;
  height: 200px;
  .blogImg {
    .blogImgBg {
      height: 150px;
      background-size: cover;
    }
    a{
      display: inline-block;
      margin-top: 5px;
      font-weight: bold;
      color: black;
      word-spacing: 2px;
      text-align: initial;
      font-family: lato;
      text-align: center;
      &:hover{
        color: #f37545;
      }
    }
  }
}
.blogImg {
  width: 100%;
}
.blogImg img {
  width: 100%;
  height: auto;
}
.blogTitle {
  font-size: 13px;
  text-decoration: underline;
  color: #f47545;
  margin-bottom: 8px !important;
}
.blogDetail {
  padding: 21px;
  width: auto;
  padding-bottom: 0;
}
.blogDetail a {
  color: black;
  text-decoration: none !important;
  font-size: 16px;
}
.blogDetail p {
  margin-bottom: 0;
}
.blogDetail a:hover {
  color: #f47545 !important;
}
.nameDate {
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 15px !important;
  margin-top: 10px !important;
}
@media screen and (max-width: 765px) {
  .blog-div {
    width: 100%;
  }
  .blogCard {
    width: 200px;
  }
}
@media screen and (max-width: 765px) {
  .blog-div {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .blogCard {
    width: 90%;
    height: auto;
    margin-top: 20px;
  }
  .blogImg {
    width: 100%;
  }
  .blogImg img {
    width: 100% !important;
    height: auto;
  }
}
footer {
  // margin-top: 60px;
}
.footer-con {
  background-color: #1f4563;

  color: white;
}
.navbar-text {
  display: inline-block;
  padding-top: 0 !important;
  padding-bottom: 0.5rem;
}
@media screen and (max-width: 765px) {
  .footer-con {
    padding: 0 !important;
  }
  .footer-con .container {
    padding: 0 !important;
  }
}
.innerFooter {
  display: flex;
  justify-content: space-around;
  padding: 15px 30px 40px 30px;
  max-width: 1366px;
  margin: auto;
}
.transparent {
  height: 65%;
  /* background: red; */
  width: 100%;
  position: absolute;
}
@media screen and (max-width: 1024px) {
  .navbar-text {
    /* display: inline-block; */
    padding-top: 0.5rem;
    padding-bottom: 0 !important;
  }
  .inner-addon {
    display: none !important;
  }
  .ipadview {
    display: inline-block;
    position: relative;
    display: block;
    font-size: 18px;
    margin-top: 4px;
  }
  .nav-search {
    top: 54px;
    border: 1px #0000001f solid !important;
    border-radius: 4px;
    outline: none !important;
  }
  .navbar-nav {
    padding-top: 0px;
  }
  .nav-search:focus {
    box-shadow: none !important;
  }
  .searchDrop {
    position: absolute;
    display: block;
    top: 65px;
    right: 10px;
    background: white;
    width: 233px;
    /* height: 60px; */
    /* display: flex; */
    display: none;
    align-items: center;
    padding: 10px;
    border: 2px solid #f47545;
    opacity: 0;
    transition: 0.3s;
  }
  .ipadview i {
    color: #19354b !important;
  }
  .innerFooter {
    flex-direction: column;
  }
  .innerFooter .details,
  .innerFooter .contact,
  .innerFooter .newsletter {
    width: 50% !important;
  }
  .innerFooter .pictures {
    margin: auto;
    text-align: center;
  }
  .innerFooter .contact {
    text-align: center;
  }
  .det-con {
    width: 100% !important;
  }
}
@media screen and (max-width: 765px) {
  .innerFooter {
    flex-direction: column;
  }
  .innerFooter .details,
  .innerFooter .contact,
  .innerFooter .newsletter {
    width: 100% !important;
  }
  .innerFooter .pictures {
    margin: auto;
    text-align: center;
  }
  .innerFooter .contact {
    text-align: center;
  }
  #firstSession .quiz {
    padding: 14px 12px;
    font-size: 16px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 50px;
    height: 40px !important;
  }
}

#about-us h2 {
  font-family: "koho-italic";
  justify-content: center;
  align-items: center;
  font-size: 35px !important;
  text-align: center;
  padding-top: 100px;
  color: #19354b
} 

@media screen and (max-width: 765px) {
  #about-us h2 {
    font-size: 15px !important;
  } 
}

.det-con {
  display: flex;
  width: 31%;
}
.news-pic {
  display: flex;
  margin-left: -80px;
}
.footerlogo {
  width: 165px;
  padding: 20px 0px;
}
.details {
  width: 232px !important;
  padding: 0px 10px 0px 24px;
}
.details a {
  color: white;
  padding: 10px;
  font-size: 12px;
}
.details a:hover {
  color: #f47545;
  text-decoration: none;
}
.details p {
  font-size: 11px;
}

.newsletter {
  padding: 20px 28px 0px 12px;
  width: 285px;
}
.contact {
  width: 145px;
  padding: 23px 10px 0px 36px;
}
.contact ul {
  list-style: none;
  padding: 0px !important;
  font-size: 10px;
  margin-top: 20px;
}
.newsletter p {
  font-size: 11px;
  width: 239px;
}
.input-group {
  width: 242px !important;
  padding: 15px 0px;
}

.cont-link {
  padding-bottom: 5px;
}
.pictures {
  width: 365px;
  padding: 7px 34px;

  margin: 0px 0px 0px 100px;
}
.pictures img {
  width: 83px;
  height: 83px;

  margin: 6px 4px;
}
@media screen and (max-width: 992px) {
  .innerFooter {
    flex-direction: column;
  }
  .details {
    width: 50% !important;
  }
  .contact {
    width: 50%;
  }
  .newsletter,
  .pictures {
    width: 50%;
  }
}
.input-group input {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  font-size: 13px;
}
.input-group input {
}
.input-group-append button {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: #f47545;
  color: white;
  font-size: 13px;
}
@media screen and (max-width: 765px) {
  .det-con,
  .news-pic {
    flex-direction: column;
    width: 100%;
    justify-content: center;
    text-align: center;
  }
  .input-group {
    width: auto !important;
  }
  .details,
  .contact,
  .newsletter,
  .pictures {
    width: 100% !important;
    align-items: center;
  }
  .innerFooter {
    padding: 20px;
  }
}
.copyright {
  background-color: #19354b;
  padding: 1px;
  color: white;
  font-size: 10px;
}
.copyright p {
  width: 41%;

  padding: 13px 0px 0px 0px;
}
.news-pic {
  margin: 0px 0px;
}
.copyright li,
.copyright a {
  display: inline;
  color: white;
  padding: 10px 0px;
}
.copyright a {
  padding: 0px 15px;
  border-right: 1px white solid;
}
.copyright a:hover {
  color: white;
  text-decoration: none;
}
.copyright ul {
  padding: 13px 0px 0px 0px;
}
.copyright-text {
  display: flex;
  justify-content: space-evenly;
  @media screen and (max-width: 600px) {
    p {
      text-align: center;
    }
    ul {
      display: none;
    }
  }
}

.steps {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  margin-top: 30px;
}

.steps .radius {
  width: 30px;
  height: 30px;
  border: 2px solid #f47545;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f47545;
  transition: 0.7s;
  box-shadow: 0;
  cursor: pointer;
}

.steps .line {
  width: 260px;
  height: 2px;
  background-color: rgba(107, 107, 107, 0.3);
}

.steps .line div {
  height: 100%;
  transition: 0.7s;
  width: 0%;
  background-color: #f47545;
}
@media screen and (max-width: 1024px) {
  .steps .line {
    width: 210px;
  }
}
@media screen and (max-width: 765px) {
  .steps .line {
    width: 150px;
  }
}

#first1,
#first2,
#first3 {
  position: absolute;
  width: 100%;
  letter-spacing: 0.2px;
  line-height: 27px;
  /*height: 100%;*/
  /*left: -12%;*/
}

@media screen and (max-width: 765px) {
  #first1,
  #first2,
  #first3 {
    position: absolute;
    width: 100%;
  }
  #first2 {
    position: relative;
  }
  .droppdownn2 {
    display: none !important;
  }
  .banner2-text {
    height: 70%;
  }
  .video {
    justify-content: center;
  }
}
.jusrAriveButtton {
  width: 100%;
  text-align: center;
  margin-top: 38px !important;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.just-Ariv .quiz {
  margin: auto;
  outline: none;
  transition: all 0.3s ease 0s;
  position: relative;
  top: 5px;
  padding: 17px 22px;
  font-size: 16px;
}
.just-Ariv .quiz:hover {
  margin-bottom: 5px;
}
.just-Ariv .quiz:hover {
  top: 0;
}

.just-Ariv .quiz a {
  text-decoration: none;
  color: white;
}
.shopNow a,
.navbar-text a {
  text-decoration: none !important;
  color: white !important;
}

.navbar-text img {
  width: 36px;
}
.navbar-collapse {
  flex-grow: 0 !important;
}
.navbar-nav {
  align-items: center !important;

  padding: 0px 15px;
}
.navbar-nav .dropdown {
  right: 130px;
  font-family: "montserrat-semibold";
}

#aboutusnav {
  right: 130px;
  font-family: "montserrat-semibold";
}
.navbar-nav li {
  margin-left: 10px;
  color: #333333;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  color: #333333;
  display: block;
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  position: relative;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-family: sans-serif;
}
.navbar-nav .nav-link {
  color: #333333 !important;
  display: flex;
  align-items: baseline;
}
.nav-link::after {
  display: none !important;
}

.text .nav-link {
  padding: 0;
}
.nav-link {
  padding: 3px 5px !important;
}
.nav-link:hover {
  color: #f47545 !important;
}
.nav-link.active:hover {
  // color: white !important;
}
/* @media (min-width: 1200px){
    .navbar .container{
    max-width: 1200px;
} */
.inner-addon {
  position: relative;
  width: 200px;
}
.nav-search {
  height: 34px !important;
  background-color: lightgray;
  background-color: #8080801a !important;
  border: none !important;
  font-size: 11px !important;
  height: 38px;
}
.collapse i {
  color: gray;
}

/* style icon */
.inner-addon .fa-search {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

/* align icon */
.left-addon .fa-search {
  left: 0px;
}
.right-addon .fa-search {
  right: 0px;
}

/* add padding  */
.left-addon input {
  padding: 0px !important;
  padding: 0px 0px 0px 36px !important;
}

.navbar-nav .quiz {
  margin: 0px;
}

.nav-link:hover {
  border-top: 3px solid #f47545;
}
.nav-link li {
  padding: 5px;
}

.startQuiz {
  height: 50px;
  display: flex;
  align-items: flex-end;
  display: flex !important;
  align-items: center;
}
.startQuiz:hover {
  text-decoration: none;
  color: white;
}

.droppdownn {
  position: absolute;
  display: flex;
  height: 270px;
  width: 360px;
  justify-content: space-between;
  border: 2px solid #f67847;
  background-color: white;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  left: -150%;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
  height: auto;
  width: 480px;
  left: -89%;
}

.droppdownn .first,
.droppdownn .second {
  width: 50%;
}

.droppdownn .first div,
.droppdownn .second div {
  height: 34px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: 0.5s;
  cursor: pointer;
}

.droppdownn .first div:hover,
.droppdownn .second div:hover {
  margin-left: 10px;
  color: #f47545;
}

.droppdownn img {
  height: auto;
  position: absolute;
  top: -18px;
  left: 60%;
  transform: translate(-50%, 0);
}

.dropdown {
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .dropdown.web {
    display: none;
  }
  .dropdown.mob {
    display: block;
  }
}

.dropdown.mob {
  display: none;
}
.dropdown.web {
  display: block;
}

.droppdownn2 {
  position: absolute;
  display: flex;
  height: auto;
  width: 360px;
  justify-content: space-between;
  border: 2px solid #f67847;
  background-color: white;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px;
  left: -150%;
  opacity: 0;
  z-index: -1;
  transition: all 0.3s ease 0s;
  height: auto;
  width: 410px;
  overflow-y: scroll;
  left: -135%;
}

.droppdownn2::-webkit-scrollbar {
  width: 5px;
}

.droppdownn2::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
}

.droppdownn2::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.droppdownn2,
.droppdownn {
  top: 43px;
}

.droppdownn2 .first,
.droppdownn2 .second {
  width: 100%;
}

.droppdownn2 .first {
  display: flex;
  flex-wrap: wrap;
}

.droppdownn2 .first div {
  width: auto;
}
.droppdownn2 .first div {
  padding-left: 0 !important;
  padding-right: 10px !important;
}
.droppdownn2 .first div,
.droppdownn2 .second div,
.droppdownn2 .first div a:hover,
.droppdownn2 .second div a:hover {
  height: 25px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  transition: 0.5s;
  cursor: pointer;
}

.droppdownn2 .first div:hover,
.droppdownn2 .second div:hover {
  padding-left: 10px !important;
  padding-right: 0 !important;
  color: #f47545 !important;
}

.droppdownn2 .first div a:hover,
.droppdownn2 .second div a:hover {
  color: #f47545 !important;
}
.droppdownn2 .first div a,
.droppdownn2 .second div a {
  color: black;
  text-decoration: none;
}

.droppdownn2 img {
  height: auto;
  position: absolute;
  top: -18px;
  left: 60%;
  transform: translate(-50%, 0);
}

.dropdown {
  overflow: hidden;
}

.dropdown-menu.show {
  display: none !important;
}

@media screen and (max-width: 765px) {
  .droppdownn,
  .droppdownn2 {
    /* display: none; */
  }
  .startQuiz {
    height: 32px;
  }
  .dropdown-menu.show {
    display: block !important;
  }
  .navbar-nav {
    align-items: flex-start !important;
  }
  .dropdown {
    overflow: auto !important;
  }
  .dropdown {
    width: 80%;
  }
  .dropdown-menu {
    height: 200px;
    overflow: scroll;
  }
  .dropdown-divider {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .carousel img {
    width: 100%;
    position: initial;
  }
  .text h2 {
    padding-bottom: 0 !important;
  }
  .carousel-item {
    padding-bottom: 60px;
  }
  .carousel-item .quiz {
    margin-top: 5px;
  }
  .dil-serv {
    margin-top: 20px;
  }
  .navbar-brand img {
    // width: 63%;
  }
  .text h2 {
    font-size: 50px !important;
    line-height: 55px !important;
    color: #19354b;
    text-align: center;
  }
  .text p {
    font-size: 18px !important;
    text-align: center;
    color: #19354b;
  }
  .quiz {
    height: auto !important;
    font-size: 12px !important;
    padding: 0 10px !important;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 24px !important;
  }
}

#hover1,
#hover2,
#hover3,
#hover4 {
  display: none;
}

.work img {
  margin: auto;
  margin-bottom: 10px;
  height: 130px;
  display: block;
}

.work-text h6 {
  font-weight: bold;
  margin: 25px 0px 20px;
  font-size: 16px;
}
.work-text p {
  font-size: 14px;
  margin-top: 6px;
  margin-bottom: 18px;
  padding: 0 5px;
}

.progressC {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: text !important;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 150px;
  height: 150px;
  background: none;
  position: relative;
}

.progressC-left {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  user-select: text !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  left: 0;
}
.progressC-bar1 {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  user-select: text !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-color: #f47545 !important;
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 100%;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
  transform: rotate(93.6deg);
}

.progressC-bar2 {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  user-select: text !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border-color: #f47545 !important;
  width: 100%;
  height: 100%;
  background: none;
  border-width: 6px;
  border-style: solid;
  position: absolute;
  top: 0;
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
  transform: rotate(180deg);
}

.progressC-right {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  user-select: text !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 0;
}
.progressC-value {
  color: #666666;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: text !important;
  border-radius: 50% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  left: 0;
}

.progressC-value .h2 {
  margin: 0;
}

.progressC-value .h2 p {
  font-family: "Old Standard TT", serif;
}

.animation__style01 h2 {
  padding: 0 !important;
}
.animation__style01 p {
  /* margin-bottom: 22px; */
}

.pie_progress__svg path {
  stroke: #f47545 !important;
}
.pie_progress__number {
  font-size: 30px !important;
  top: 45% !important;
}
.pie_progress__label {
  margin-top: 20px !important;
}

.pie_progress {
  width: 130px !important;
}

.controlProduct {
  position: absolute;
  top: 50%;
  width: 100;
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
  left: 50%;
  transform: translate(-50%, 0px);
  z-index: 2;
}
.controlProduct .btn {
  outline: none;
  box-shadow: none !important;
}
.controlProduct i {
  font-size: 50px !important;
  color: gray !important;
}
.controlProduct i:hover {
  color: black !important;
}

.product-img {
  width: 230px;
  height: 190px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.product-img .hoverDiv {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  transition: 0.7s;
  opacity: 0;
  position: relative;
}

#magic-zoom {
  position: fixed;
  background-color: white;
  z-index: 100;
}

#magic-zoom-text {
  color: gray;
  text-align: center;
  margin-top: 10px;
}

.product-img .hoverDiv .hoverI {
  background-color: #19354b;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-img .hoverDiv .hoverI svg {
  color: white;
  font-size: 22px;
}

.skinQuizBut {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  height: 50px;
}

.skinQuizBut .quiz {
  outline: none;
  transition: all 0.3s ease 0s;
  padding: 17px 22px;
  font-size: 16px;
  margin-top: 5px;
  padding: 0 18px;
  height: 34px;
}

.skinQuizBut .shopNow {
  margin-top: 5px;
  width: 148px;
  height: 34px;
  transition: all 0.3s ease 0s;
}

.skinQuizBut button:hover {
  margin-top: 0px;
}

.brands .controlProduct {
  width: 100%;
}

.nav-item a:hover {
  color: #f47545;
}

.div2 {
  display: none;
}

.div1 {
  display: block;
}

@media screen and (max-width: 765px) {
  .div2 {
    display: block;
  }

  .div1 {
    display: none;
  }

  .controlProduct {
    top: 40%;
  }

  .carousel-item {
    padding: 0;
    height: 100%;
    text-align: center;
  }

  .banner1.two {
    //old
    background-size: cover;
    height: 300px;
    background-position: center;
    margin-top: 40px;
    display: flex;
    margin-top: 0;

    //new
    // background-size: 100%;
    // height: 300px;
    // background-position: center;
    // margin-top: 40px;
    // display: flex;
    // margin-top: 0;
  }
  .banner1-text h5 {
    font-size: 12px !important;
    margin-bottom: 5px;
  }
  .banner1-text p {
    font-size: 8px;
    width: 150px;
    margin: auto;
  }
  .banner1-text .skinQuizBut {
    margin-top: 0;
    align-items: flex-start;
  }
  .banner1-text .skinQuizBut button {
    height: 30px !important;
    width: 140px !important;
    font-size: 12px !important;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
  }

  .details {
    padding: 0 40px;
  }
  .contact {
    padding: 0 40px;
    padding-top: 30px;
  }
  .newsletter {
    padding: 10px;
    justify-content: center;
  }
  .newsletter .input-group {
    margin: 0 !important;
  }
  .newsletter p {
    margin: auto;
  }
  .pictures {
    padding: 7px 6px;
  }
  .copyright a {
    padding: 0px 3px;
  }
  .mobileHide {
    display: none;
  }
}
.waveDiv {
  display: none;
}
.waveDiv2 {
  display: none;
}
.pie_progress {
  margin-bottom: 0px;
}
.carousel-style-change {
  height: 110%;
}
.carousel-style-change .carousel-inner {
  top: 7%;
  height: 100%;
}
.carousel-style-change .carousel-inner .carousel-item.active {
  height: 100%;
}
.team-img.active {
  width: 100px !important;
  height: 100px !important;
  opacity: 1;
}
.team {
  position: absolute !important;
}
.carousel-control-prev-icon {
  display: none;
}
.carousel-indicators {
  width: 65% !important;
  justify-content: space-evenly !important;
}

.insideMainPRoduct {
  margin: auto;
}
.product {
  width: auto;
}
/* product slider */

/* Slider */

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.webBlog {
  display: block;
}
.mobileBlog {
  display: none;
}
@media screen and (max-width: 765px) {
  .inner-addon {
    width: 100%;
  }
  .navbar-nav {
    padding: 10px 0px;
  }
  .webBlog {
    display: none;
  }
  .controlProduct i {
    font-size: 25px !important;
    color: gray !important;
  }
  .mobileBlog {
    display: block;
  }
  .mobileBlog .carousel-indicators li {
    background-color: black;
  }
  .mobileBlog .carousel-indicators {
    top: 95%;
  }
  .mobileBlog .carousel img {
    left: 0 !important;
  }
  .mobileBlog .carousel-item {
    height: auto;
    padding-bottom: 20px;
  }
  .mobileBlog .carousel-indicators .active {
    background-color: #f47545;
  }
  .mobileBlog .carousel-indicators li {
    /* border-radius: 50%;
        height: 10px;
        width: 10px; */
  }
}

@media screen and (max-width: 997px) and (min-width: 768px) {
  /* .droppdownn, .droppdownn2{
        display: none;
    } */
  .navbar-text {
    display: flex !important;
    align-items: center !important;
    /* justify-content: space-between; */
    justify-content: center !important;
    padding-bottom: 0.5rem !important;
  }

  .inner-addon {
    display: block !important;
  }
  .navbar-nav {
    padding-top: 15px;
  }
  .navbar a .mobSView {
    display: none;
  }
  .navbar-collapse.inner-addon {
    position: relative;
    width: 100%;
  }
  .inner-addon {
    position: relative;
    width: 200px;
  }
  .inner-addon {
    width: 100%;
  }
  .nav-search {
    height: 34px !important;
    background-color: lightgray;
    background-color: #8080801a !important;
    border: none !important;
    font-size: 11px !important;
    height: 38px;
  }
  .collapse i {
    color: gray;
  }

  /* style icon */
  .inner-addon .fa-search {
    position: absolute;
    padding: 10px;
    pointer-events: none;
  }

  /* align icon */
  .left-addon .fa-search {
    left: 0px;
  }
  .right-addon .fa-search {
    right: 0px;
  }

  /* add padding  */
  .left-addon input {
    padding: 0px !important;
    padding: 0px 0px 0px 36px !important;
  }

  .curate {
    flex-wrap: wrap !important;
  }
  .jusrAriveButtton .quiz {
    margin: 0 !important;
  }
  .curate .cream {
    width: 50% !important;
  }
  .curate .match {
    width: 50% !important;
  }
  .curate .match p {
    text-align: center !important;
  }
  .progres-div {
    width: 100%;
    margin-top: 50px;
  }
  .progressDashDivMain .p {
    width: 46%;
    margin-left: auto;
    margin-right: 50px;
  }
  .div2 {
    display: block;
  }
  .Delivery .deliDiv {
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
  }
  .work-con .steps {
    display: none;
  }
  .work-con .work {
    margin-top: 40px;
    flex-wrap: wrap;
  }
  .work #workDiv1,
  .work #workDiv2,
  .work #workDiv3,
  .work #workDiv4 {
    width: 49%;
    margin-top: 10px;
  }
  .banner {
    background-image: none !important;
    background-color: #fcebd9;
    height: 500px !important;
  }
  .banner .video {
    /* margin-top: 105px; */
  }
  .banner .video img {
    width: 100%;
  }
  .banner .whyWe {
    width: 50% !important;
  }
  .tabProView {
    display: block !important;
  }
  .webProView {
    display: none;
  }
  .mobProView {
    display: none;
  }
  .product-div-2 {
    display: block;
  }
  .product {
    width: 260px !important;
    margin: auto;
  }
  .banner1 {
    //old
    justify-content: flex-end;
  }
  .banner1 .banner1-text {
    //old
    width: 55%;
    padding-right: 30px;

    //new
    // width: 50%;
    // padding-right: 30px;
  }
  .webSilder {
    display: none;
  }
  .mobileSilder {
    display: none;
  }
  .tabSilder {
    display: block !important;
  }
  .tabSilder .carousel-item img {
    width: 30% !important;
    position: inherit !important;
    transform: none !important;
  }
  .tabSilder .carousel-item div {
    display: flex;
    justify-content: space-evenly;
  }
  .tabSilder .controlProduct {
    top: 25%;
  }
  .banner2 {
    background-size: cover;
    background-position: center;
    height: 500px;
  }
  #firstSession .text {
    position: absolute;
    top: 5%;
    left: 10%;
    width: 44%;
  }
}

.tabProView {
  display: none;
}

.tabSilder {
  display: none;
}
@media screen and (max-width: 765px) {
  .navbar-text {
    /* display: inline-block; */
    padding-top: 0.5rem;
    padding-bottom: 0.5rem !important;
    text-indent: 10px;
  }
  .inner-addon {
    display: block !important;
  }
  .navbar a .mobSView {
    display: none;
  }
}
.navbar-text {
  display: flex !important;
  align-items: center !important;
  /* justify-content: space-between; */
  justify-content: start;
}

.inner-addon {
  display: block;
}

@media screen and (max-width: 1200px) and (min-width: 998px) {
  /* .droppdownn, .droppdownn2{
        display: none;
    } */

  .inner-addon {
    display: none !important;
  }
  .navbar-nav {
    padding-top: 0px !important;
    width: auto;
  }
  .navbar a .mobSView {
    display: block;
  }
  .navbar-collapse.inner-addon {
    position: relative;
    width: 100%;
  }
  .inner-addon {
    position: relative;
    width: 200px;
  }
  .inner-addon {
    width: 100%;
  }
  .nav-search {
    height: 34px !important;
    background-color: lightgray;
    background-color: #8080801a !important;
    border: none !important;
    font-size: 11px !important;
    height: 38px;
  }
  .collapse i {
    color: gray;
  }

  /* style icon */
  .inner-addon .fa-search {
    position: absolute;
    padding: 10px;
    pointer-events: none;
  }

  /* align icon */
  .left-addon .fa-search {
    left: 0px;
  }
  .right-addon .fa-search {
    right: 0px;
  }

  /* add padding  */
  .left-addon input {
    padding: 0px !important;
    padding: 0px 0px 0px 36px !important;
  }

  .curate {
    flex-wrap: wrap !important;
  }
  .jusrAriveButtton .quiz {
    margin: 0 !important;
  }
  .curate .cream {
    width: 50% !important;
  }
  .curate .match {
    width: 50% !important;
  }
  .curate .match p {
    text-align: center !important;
  }
  .progres-div {
    width: 100%;
    margin-top: 50px;
  }
  .progressDashDivMain .p {
    width: 46%;
    margin-left: auto;
    margin-right: 50px;
  }
  .div2 {
    display: block;
  }
  .rec-div {
    display: none;
  }
  .Delivery .deliDiv {
    align-items: center;
    justify-content: space-between;
    text-align: center;
    flex-direction: column;
  }
  .work-con .steps {
    display: none;
  }
  .work-con .work {
    margin-top: 40px;
    flex-wrap: wrap;
  }
  .work #workDiv1,
  .work #workDiv2,
  .work #workDiv3,
  .work #workDiv4 {
    width: 49%;
    margin-top: 10px;
  }
  .banner {
    background-image: none !important;
    background-color: #fcebd9;
    height: 500px !important;
  }
  .banner .video {
    /* margin-top: 105px; */
  }
  .banner .video img {
    width: 100%;
  }
  .banner .whyWe {
    width: 50% !important;
  }
  .tabProView {
    display: block !important;
  }
  .webProView {
    display: none;
  }
  .mobProView {
    display: none;
  }
  .product-div-2 {
    display: block;
  }
  .product {
    width: 260px !important;
    margin: auto;
  }
  .banner1 {
    //old
    justify-content: flex-end;
  }
  .banner1 .banner1-text {
    //old
    width: 55%;
    padding-right: 30px;

    //new
    // width: 50%;
    // padding-right: 30px;
  }
  .webSilder {
    display: none;
  }
  .mobileSilder {
    display: none;
  }
  .tabSilder {
    display: block !important;
  }
  .tabSilder .carousel-item img {
    width: 30% !important;
    position: inherit !important;
    transform: none !important;
  }
  .tabSilder .carousel-item div {
    display: flex;
    justify-content: space-evenly;
  }
  .tabSilder .controlProduct {
    top: 25%;
  }
  .banner2 {
    background-size: cover;
    background-position: center;
    height: 500px;
  }
  #firstSession .text {
    position: absolute;
    top: 5%;
    left: 10%;
    width: 44%;
  }
}

/*Shahzad Stylesheet*/
.checkout-page {
  padding: 30px;
  @media screen and (max-width: 768px) {
    padding: 10px;
    overflow: hidden;
  }
}
.checkout-page label p {
  font-size: 13px;
}
.checkout-page p {
  padding-left: 0px;
}
.checkout-page a {
  color: black;
  text-align: left;
}
.checkout-page a:hover {
  text-decoration: none;
  color: #f47545;
}
.checkout-con h2 {
  text-align: center;
  padding: 20px;
  /* background-image: url(./Images/underline.png); */
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 30px;
  margin-bottom: 20px;
}
.check-div {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
}

.bg-gray {
  background-color: #efefef;
}

.social-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.social-links li {
  float: left;
}

.productDetailMob {
  display: none !important;
  @media screen and (max-width: 1024px) {
    display: block !important;
    height: auto;
    .carousel-inner {
      width: 100%;
      height: 40vh;
      img {
        height: 100% !important;
        width: auto !important;
        left: 0;
        margin: auto;
      }
    }
    li {
      background-color: #f37545 !important;
      height: 8px;
      width: 8px;
      border-radius: 50%;
    }
    .carousel-indicators {
      justify-content: center !important;
    }
  }
}

.preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    &.productDetailWeb {
      display: none;
    }
  }
}
@media screen and (max-width: 996px) {
  .preview {
    margin-bottom: 20px;
  }
}

.preview-pic {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.preview-thumbnail.nav-tabs {
  border: none;
  margin-top: 15px;
}
.preview-thumbnail.nav-tabs li {
  width: 25%;
}
.preview-thumbnail.nav-tabs li img {
  max-width: 100%;
  display: block;
  height: 80px;
  object-fit: contain;
  background-size: cover;
  border: 1px solid #f0f0f0;
  margin-top: 10px;
}
.preview-thumbnail.nav-tabs li a {
  padding: 0;
  margin: 0;
}
.preview-thumbnail.nav-tabs li:last-of-type {
  margin-right: 0;
}

.tab-content {
  overflow: hidden;
}
.tab-content img {
  width: 100%;
  height: 320px;
  object-fit: contain;
  background-size: cover;
  top: 0;
  left: 0;
  background-repeat: no-repeat;
  border: 1px solid #f0f0f0;
  -webkit-animation-name: opacity;
  animation-name: opacity;
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
}

.card {
  /* background: #eee; */
  border: none !important;
  line-height: 1.5em;
}

@media screen and (min-width: 997px) {
  .wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.details {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.colors {
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
  text-transform: UPPERCASE;
  font-weight: bold;
}

.checked,
.price span {
  color: #ff9f1a;
}

.product-title,
.rating,
.product-description,
.price,
.vote,
.sizes {
  margin-bottom: 0px;
  margin-top: -8px;
}

.product-title {
  margin-top: 0;
}

.size {
  margin-right: 10px;
}
.size:first-of-type {
  margin-left: 40px;
}

.color {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  height: 2em;
  width: 2em;
  border-radius: 2px;
}
.color:first-of-type {
  margin-left: 20px;
}

.add-to-cart,
.like {
  background: #ff9f1a;
  padding: 1.2em 1.5em;
  border: none;
  text-transform: UPPERCASE;
  font-weight: bold;
  color: #fff;
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.add-to-cart:hover,
.like:hover {
  background: #b36800;
  color: #fff;
}

.not-available {
  text-align: center;
  line-height: 2em;
}
.not-available:before {
  font-family: fontawesome;
  content: "\f00d";
  color: #fff;
}

.orange {
  background: #ff9f1a;
}

.green {
  background: #85ad00;
}

.blue {
  background: #0076ad;
}

.tooltip-inner {
  padding: 1.3em;
}

@-webkit-keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes opacity {
  0% {
    opacity: 0;
    -webkit-transform: scale(3);
    transform: scale(3);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*# sourceMappingURL=style.css.map */

.product-title {
  font-size: 18px;
  line-height: 23px;
  color: #19354b;
  font-weight: 600 !important;
  font-family: "Lato";
  margin-bottom: 2px;
  text-transform: capitalize;
  &.quickTitle {
    width: 500px;
  }
}

.review-title {
  font-size: 17px;
  line-height: 20px;
  margin-top: 15px;
  color: #19354b;
  font-weight: 600 !important;
  font-family: "Lato";
}
.review-text {
  text-align: left !important;

  border: 1px solid #efefef !important;
}

.review-text:focus {
  box-shadow: none !important;
}

.input-rating {
  border-radius: 0px !important;
  min-height: 40px !important;
  margin-bottom: 10px !important;
  border: 1px solid #efefef !important;
  font-size: 13px !important;
}

.input-rating:focus {
  box-shadow: none !important;
  border: 1px solid #efefef;
}

.product-stars {
  color: #f6875f;
  font-size: 11px;
}

.fs {
  font-family: "Lato";
}

.colord {
  color: #f47545;
  font-weight: 600;
}
.disabled {
  color: #c7c7c7;
  font-size: 16px;
  margin-left: 2px;
}
.btn-cart {
  background-color: #f4875e !important;
  color: white !important;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px !important;
  width: 120px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  text-align: center;
  border-radius: 100px !important;
}

.btn-cart:hover {
  transform: scale(1.06);
  transition: 0.3s all;
}

.btn-left {
  background-color: transparent;
  color: gray;
  border: 1px solid lightgray !important;
  height: 30px !important;
  line-height: 1 !important;
  border-right: 0px !important;
  border-radius: 20px 0px 0px 20px !important;
  border: 1px solid #ced4da;
}

.btn-left2 {
  background-color: transparent;
  color: gray;
  border: 1px solid lightgray !important;
  height: 30px !important;
  line-height: 1 !important;
  border-radius: 20px 0px 0px 20px !important;
  border: none !important;
}

.btn-right {
  background-color: transparent;
  color: gray;
  border: 1px solid lightgray !important;
  height: 30px !important;
  line-height: 1 !important;
  border-left: none !important;
  border-radius: 0px 20px 20px 0px !important;
  border: 1px solid #ced4da;
}

.btn-right2 {
  background-color: transparent;
  color: gray;
  border: 1px solid lightgray !important;
  height: 30px !important;
  line-height: 1 !important;
  border: none !important;
}

.btn-left span {
  font-weight: 600;
  font-size: 12px;
}
.btn-right span {
  font-weight: 600;
  font-size: 12px;
}
.input-number {
  max-width: 50px !important;
  text-align: center !important;
  height: 30px !important;
  border-left: none !important;
  border-right: none !important;
  border: 1px solid #ced4da;
}

.input-number:focus {
  box-shadow: none !important;
}

.btn-left:focus {
  box-shadow: none !important;
}

.btn-left2:focus {
  box-shadow: none !important;
}

.btn-right:focus {
  box-shadow: none !important;
}

.btn-right2:focus {
  box-shadow: none !important;
}

.plus-minus {
  position: absolute !important;
  margin-left: 30px;
  margin-top: -13px;
}

.plus-minus-2 {
  margin-left: 0px;
  width: 100px !important;
  padding: 0px;
}

#pro-detail {
}

.pad-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.pad-0 a {
  background-color: #f5f6f8;
  padding: 30px;
  height: 60px;
  line-height: 3;
  text-transform: uppercase;
  font-weight: 550;
  font-size: 13px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pad-0 a:hover {
  box-shadow: none !important;
  border: 0px !important;
}
.nav-link.active,
.nav-item.show .nav-link {
  // color: white !important;
  // background-color: #f4875e !important;
}

.tab-pane {
  font-size: 14px;
  padding: 10px;
  text-align: justify;
}

.user-img {
  width: 60px !important;
  height: 60px !important;
  border: 0px !important;
}

.rating-stars {
  color: #fe8a00 !important;
  font-size: 10px !important;
}

.btn-recom {
  background-color: #19354b !important;
  padding: 5px !important;
  width: 170px;
  font-size: 123px;
  color: white;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  margin-bottom: 10px;
  margin-top: 150px;
}
.btn-recom:hover {
  transform: scale(1.05);
  transition: 0.2s all;
}

.rating-img {
  margin: 0px auto;
  border-right: 1px solid #efefef;
}
.user-name {
  color: #f4875e;
  font-size: 16px;
}
.date {
  font-size: 14px;
  color: #b7b6b6;
}
.chart-scroll {
  margin-left: 100px;
}

.btn-seemore {
  font-size: 13px !important;
  padding: 0px !important;
  font-weight: 550 !important;
  margin-top: 0px;
}
.see-more:hover {
  color: #f47646;
}

.btn-match {
  width: 60px;
  height: 60px;
  font-size: 12px !important;
  text-align: center;
  color: white !important;
  position: absolute;
  margin-left: -60px;
  border-radius: 100px !important;
  background-color: #f47646 !important;
}

.btn-heart {
  width: 50px;
  height: 50px;
  font-size: 25px !important;
  text-align: center;
  color: #f47545 !important;
  border-radius: 100px !important;
  background-color: #dadada !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}
.btn-heart:focus {
  box-shadow: none !important;
}

.btn-heart:active {
  box-shadow: none !important;
}

.productDiv {
  display: none;
}
.mb-5-mine {
  margin-bottom: 25px !important;
}

.mb-show {
  display: none !important;
}

/*Shop cards*/

@media screen and (max-width: 600px) {
  .mobviewpro {
    flex-direction: column;
  }
  .productDiv {
    width: 100%;
  }
}

/*Ratigs*/

fieldset,
label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: right;
}

.rating > input {
  display: none;
}
.rating > label:before {
  margin: 5px;
  font-size: 1.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

.rating > .half:before {
  content: "\f089";
  position: absolute;
}
.fieldset {
  margin: 0px !important;
  padding: 0px !important;
}
.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating > input:checked ~ label, /* show gold star when clicked */
.rating:not(:checked) > label:hover, /* hover current star */
.rating:not(:checked) > label:hover ~ label {
  color: #fe8a01;
} /* hover previous stars in list */

.rating > input:checked + label:hover, /* hover current star when changing rating */
.rating > input:checked ~ label:hover,
.rating > label:hover ~ input:checked ~ label, /* lighten current selection */
.rating > input:checked ~ label:hover ~ label {
  color: #fe8a01;
}

/*Profile Design*/
.profile-navs {
  height: 90px;
  background-color: transparent;
  border-radius: 0px !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  .nav-item {
    width: 33.33%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
.profile-navs li a {
  height: 90px;
  text-align: center !important;
  line-height: 5;
  font-size: 17px;
  font-weight: 600;
  color: #19354b;
  border-radius: 0px !important;
  text-transform: capitalize;
  background-color: white;
}

.profile-navs li a i {
  font-size: 22px;

  margin-right: 3px;
}

.profile-navs li {
  border-left: 1px solid #efefef;
  border-radius: 0px !important;
  background-color: white;
}

.profile-navs li:nth-child(1) {
  border-left: none !important;
}

.profile-navs li a .active {
  background-color: #19354b !important;
}

.profile-title {
  font-size: 60px;
  margin-bottom: 0px;
}

.profile-sub-title {
  font-size: 30px;
  margin-bottom: 0px;
}

/*Navas for Profile*/
.nav-link.active,
.nav-item.show .nav-link .profile-navs {
  /* color: white !important;
    background-color: #f47545 !important; */
  color: #f47545 !important;
}

.profilee {
  .nav-link.active,
  .nav-item.show .nav-link .profile-navs {
    color: white !important;
    background: #f47545;
  }
  .tab-pane {
    p,
    b {
      font-size: 17px;
      text-align: justify;
      font-family: "math";
    }
  }
  .seconddd {
    padding: 20px;
    @media screen and (max-width: 1024px) {
      .sm10 {
        max-width: 90% !important;
      }
      .ico {
        max-width: 10% !important;
        margin-top: 0 !important;
        padding-left: 0 !important;
      }
    }
  }
  .price {
    display: flex;
    justify-content: start;
    font-size: 16px !important;
    @media screen and (max-width: 800px) {
      margin-bottom: 30px;
      font-size: 16px !important;
      width: 100%;
      display: flex;
      align-items: center;
    }
    span,
    i {
      color: #212529e0 !important;
      font-size: 16px !important;
      @media screen and (max-width: 800px) {
        font-size: 16px !important;
      }
      i {
        @media screen and (max-width: 800px) {
          font-size: 14px !important;
        }
      }
    }
    .lightGray {
      color: gray !important;
      font-size: 16px !important;
      margin-right: 10px;
      @media screen and (max-width: 800px) {
        font-size: 16px !important;
        &.two {
          display: inline;
        }
      }
      span,
      i {
        color: gray !important;
        font-size: 16px !important;
        @media screen and (max-width: 800px) {
          font-size: 16px !important;
        }
      }
      i {
        font-size: 14px !important;
        @media screen and (max-width: 800px) {
          font-size: 14px !important;
        }
      }
    }
    .seperatorrr {
      margin-left: 5px !important;
      margin-right: 5px !important;
    }
    .pinkk {
      color: #dc3545 !important;
      font-size: 16px !important;
      @media screen and (max-width: 800px) {
        font-size: 16px !important;
        text-transform: lowercase;
      }
    }
  }
  #wallet {
    padding: 0 !important;
    .walletTop {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      .fa-wallet {
        font-size: 60px;
        color: #f47545;
      }
      .walletBalance {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        .amt {
          font-size: 16px;
          i {
            color: #495057 !important;
          }
        }
        .txt {
          color: rgba(0, 0, 0, 0.527);
        }
      }
      .ques {
        width: 100%;
        text-align: center;
        font-weight: 600;
        font-size: 16px;
        margin-top: 30px;
      }
    }
    .walletSecond {
      display: flex;
      justify-content: space-evenly;
      .secondFirst {
        width: 100%;
      }
      .secondFirst {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 80px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
          "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
        h3,
        h4 {
          font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
            "Helvetica Neue", Arial, "Noto Sans", sans-serif,
            "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
            "Noto Color Emoji";
        }
        h4 {
          font-size: 14px;
          color: #495057;
        }
        h6 {
          font-size: 16px;
          margin-top: 5px;
          color: #495057;
        }
        b {
          font-size: 12px;
          font-weight: 400;
          color: gray;
          text-align: center;
        }
        h6 {
          font-size: 14px;
        }
        h3 {
          color: #495057;
          font-size: 18px;
          font-weight: 500 !important;
        }
        .SocialIcons {
          margin-top: 8px;
          display: flex;
          i {
            font-size: 45px;
            margin-left: 15px;
            margin-right: 15px;
          }
          .fa-facebook-square {
            color: #1777f2;
          }
          .fa-twitter-square {
            color: #1ca1f2;
          }
          .fa-google-plus-square {
            color: #e94235;
          }
        }
      }
    }
  }
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: none !important;
  background-size: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lable-control {
  color: gray;
  font-size: 14px;
}
.accont-fields {
  height: 42px !important;
  border-radius: 0px !important;
  font-size: 14px;
}
.form-control:focus {
  box-shadow: none !important;
}

.mb-mobile {
  margin-top: -40px;
}

.img-skin {
  width: 190px !important;
  border: none !important;
  height: 190px !important;
}

.img-polution {
  width: 240px !important;
  border: none !important;
  height: 240px !important;
}
.card-profile {
  padding: 35px;
  margin-bottom: 15px;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  background: #fff;
}
.age {
  margin-top: -10px;
  color: #f47545;
  font-size: 40px;
}

/* Custom Radio Button Start*/

.radiotextsty {
  color: #000000;
  font-size: 18px;
  text-transform: uppercase;
}

.customradio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.customradio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: white;
  background-color: #dedede !important;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.customradio:hover input ~ .checkmark {
  background-color: transparent;
}

/* When the radio button is checked, add a blue background */
.customradio input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid #bebebe;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.customradio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.customradio .checkmark:after {
  top: -1px;
  left: -1px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #f37545;
}

.form-check-inline {
  margin-bottom: 15px !important;
}
/* Custom Radio Button End*/

.sleep-hours {
  font-size: 27px;
  font-weight: 550;
}

/*Tick checkbox*/

.checkbox-custom,
.radio-custom {
  opacity: 0;
  position: absolute;
}

.checkbox-custom,
.checkbox-custom-label,
.radio-custom,
.radio-custom-label {
  display: inline-block;
  vertical-align: middle;
  margin: 30px;
  font-size: 22px;
  font-weight: 550;
  cursor: pointer;
}

.checkbox-custom-label,
.radio-custom-label {
  position: relative;
}

.checkbox-custom + .checkbox-custom-label:before,
.radio-custom + .radio-custom-label:before {
  content: "";
  background: #fff;
  border: 2px solid #f57645;
  display: inline-block;
  vertical-align: middle;
  width: 30px;
  height: 30px;
  padding: 1px;
  margin-right: 10px;
  text-align: center;
}

.checkbox-custom:checked + .checkbox-custom-label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  background: rebeccapurple;
  color: #fff;
}

.radio-custom + .radio-custom-label:before {
  border-radius: 50%;
}

.radio-custom:checked + .radio-custom-label:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #f57645;
  padding: -10px 0px 0px 0px;
}

.checkbox-custom:focus + .checkbox-custom-label,
.radio-custom:focus + .radio-custom-label {
}

.btn-theme {
  background-color: #f47545 !important;
  color: white !important;
  border-radius: 20px !important;
  min-width: 100px;
  font-size: 13px !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.btn-theme:hover {
  transform: scale(1.05);
  transition: 0.2s all;
}

.padd-0 {
  padding: 0px !important;
}

.tb-my thead tr th {
  font-size: 14px;
  color: #232530;
  border-bottom: 1px solid #eeeeee !important;
}

.tb-my tbody tr td {
  font-size: 14px;
  color: #232530;
  line-height: 7;
  border-bottom: 1px solid #eeeeee !important;
  padding: 0 !important;
}
.qty-orders {
  margin-top: 35px;
  border: none;
  max-width: 50px !important;
  text-align: center !important;
  height: 30px !important;
  border: none !important;
}

.qty-orders:focus {
  box-shadow: none !important;
  border: none !important;
}

.img-orders {
  width: 60px !important;
  border: none !important;
  height: 60px !important;
}

.price-text {
  color: #f47545;
  font-weight: 600;
  font-size: 14px;
}

.pro-title {
  font-weight: 600;
  font-size: 14px;
}

.btn-default-orders {
  border: 1px solid #eeeeee !important;
  font-size: 11px !important;
}

.login-cont {
  width: 450px;
  margin: 0px auto;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-radius: 0.25rem;
  background: #fff;
}

.signup-cont {
  width: 550px;
  margin: 0px auto;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border-radius: 20px;
}

.btn-login {
  background-color: #f47545 !important;
  font-size: 15px;
  margin-top: 10px;
  color: white !important;
  width: 140px;
  border-radius: 50px !important;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  &.reccom {
    margin-right: 20px;
    @media only screen and (max-width: 768px) {
      margin-right: 0px;
    }
  }
  &.signupp {
    font-size: 18px;
    margin-top: 0 !important;
  }
}
.btn-login:hover {
  transform: scale(1.06);
  transition: 0.3s all;
}

.input-signup {
  border-radius: 0px !important;
  font-size: 14px !important;
  min-height: 40px !important;
}

/*Horizontal Line*/

.hr-line {
  display: flex;
  color: gray;
  font-size: 13px;
  flex-direction: row;
}
.hr-line:before,
.hr-line:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #e5e5e5;
  margin: auto;
}
.hr-line:before {
  margin-right: 10px;
}
.hr-line:after {
  margin-left: 10px;
}

.login-link {
  color: gray !important;
  margin-bottom: 30px !important;
  margin-top: 10px !important;
  margin: auto;
  transition: 0.3s;
  a {
    color: #dc6e45 !important;
  }
  a:hover {
    color: #dc6e45 !important;
  }
  // display: block;
}

.btn-facebook {
  background-color: #3b5998 !important;
  color: white !important;
  width: 150px;
  margin-bottom: 10px !important;
  border-radius: 0px !important;
}
.btn-google:hover {
  transform: scale(1.06);
  transition: 0.3s all;
}

.btn-facebook:hover {
  transform: scale(1.06);
  transition: 0.3s all;
}

.btn-google {
  background-color: #dd4b39 !important;
  color: white !important;
  width: 150px;
  margin-bottom: 10px !important;
  border-radius: 0px !important;
}

.shadow-custom {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  margin-top: 20px;
}

.about-tabs:hover {
  border: none !important;
}
.about-tabs li a {
  font-size: 14px;
  color: #19354b !important;
  margin-bottom: 5px !important;
  background-image: url(./Images/about/background.png);
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-size: contain;
  object-fit: fill;
  &:hover {
    border: none;
  }
}

#profile-tab {
  /* background-color: transparent !important; */
}

#contact-tab {
  background-color: transparent !important;
}

.about-tabs li {
  background-color: transparent !important;
}

.about-tabs li a:hover {
  font-size: 14px;
  border: 1px solid white !important;
  background-color: transparent !important;
}

.about-tabs li a:focus {
  color: white !important;
  background-color: transparent !important;
  background-image: url(./Images/about/background-active.png) !important;
}

.about-tabs li a span {
  font-size: 30px;
  margin-top: 0px;
  margin-left: 5px;
  color: #dad9dc;
  position: inherit;
}

body {
  font-family: sans-serif;
  overflow-x: hidden !important;
}

@font-face {
  font-family: "jenna";
  src: url(./css/fonts/JennaSue.ttf);
}

@font-face {
  font-family: "montserrat-semibold";
  src: url(./css/fonts/Montserrat-SemiBold.ttf);
}

@font-face {
  font-family: "montserrat-medium";
  src: url(./css/fonts/Montserrat-Medium.ttf);
}

@font-face {
  font-family: "montserrat-regular";
  src: url(./css/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: "koho-italic";
  src: url(./css/fonts/KoHo-Italic.ttf);
}

@font-face {
  font-family: "raleway-light";
  src: url(./css/fonts/Raleway-Light.ttf);
}

@font-face {
  font-family: "raleway-regular";
  src: url(./css/fonts/Raleway-Regular.ttf);
}

@font-face {
  font-family: "raleway-medium";
  src: url(./css/fonts/Raleway-Medium.ttf);
}

@font-face {
  font-family: "indie";
  src: url(./css/fonts/IndieFlower-Regular.ttf);
}

@font-face {
  font-family: "indie";
  src: url(./css/fonts/IndieFlower-Regular.ttf);
}

@font-face {
  font-family: "amiri-bold-italic";
  src: url(./css/fonts/Amiri-BoldItalic.ttf);
}

@font-face {
  font-family: "amiri-regular";
  src: url(./css/fonts/Amiri-Regular.ttf);
}

@font-face {
  font-family: "amiri-italic";
  src: url(./css/fonts/Amiri-Italic.ttf);
}

/*---------About Top --------------*/

#abtop {
  margin-top: -10px;
}

#ab-1 {
  margin-top: 12vw;
  margin-left: 6vw;
  width: 15vw;
}

#ab-2 {
  margin-top: 8vw;
  margin-left: 80vw;
  width: 15vw;
}

#ab-3 {
  margin-top: 35vw;
  margin-left: 6vw;
  width: 15vw;
}

#ab-4 {
  margin-top: 31vw;
  margin-left: 45vw;
  width: 7vw;
}

#ab-5 {
  margin-top: 33vw;
  margin-left: 70vw;
  width: 15vw;
}

#aboverlay {
  width: 57vw;
  margin-top: 15vw;
  margin-left: 20vw;
  text-align: center;
}

#aboverlay .h6 {
  line-height: 30px;
  font-weight: bolder;
  font-size: 20px;
}

#aboverlay .h2 {
  line-height: 30px;
  font-weight: bolder;
  font-family: "jenna";
  font-size: 60px;
  margin-top: 24px;
}

@media only screen and (max-width: 1024px) {
  #abtop {
    margin-top: 20px;
  }

  #aboverlay {
    margin-top: 10vw;
  }
  #aboverlay .h6 {
    margin-top: -15px;
  }

  #aboverlay .h2 {
    margin-top: -2px;
  }
}

@media only screen and (max-width: 768px) {
  #abtop {
    margin-top: 40px;
  }

  #ab-1 {
    margin-top: 100px;
  }

  #aboverlay {
    margin-top: 0vw;
    margin-left: 0px;
    text-align: center;
    width: 100%;
    position: relative;
  }
}

@media only screen and (max-width: 600px) {
  .text-inf {
    font-size: 15px !important;
    line-height: 1.5;
  }

  .process-text {
    font-size: 25px !important;
    font-weight: bold !important;
    font-family: "koho-italic" !important;
  }

  #home-tab,
  #profile-tab,
  #contact-tab {
    font-size: 16px !important;
  }
  .process-no {
    line-height: 4.5 !important;
  }
  #ab-1 {
    margin-top: 12vw;
  }

  #abtop {
    margin-top: 60px;
  }

  #aboverlay {
    margin-top: 0vw;
    margin-left: 0px;
    text-align: center;
    width: 100%;
    position: relative;
  }
}

/*---------about Top  END--------------*/

/*---------about Parts --------------*/

#abcard {
  width: 250px;
  background-color: transparent;
  border: none;
}

#abcard1-head {
  font-size: 40px;
  font-weight: 700;
  margin-top: -20px;
  background-color: transparent;
  font-family: "jenna";
}

#abcard2-head {
  font-size: 40px;
  font-weight: 700;
  margin-top: 25px;
  background-color: transparent;
  font-family: "jenna";
}

#abcard3-head {
  font-size: 40px;
  font-weight: 700;
  margin-top: 35px;
  background-color: transparent;
  font-family: "jenna";
}

#card-img-arrow {
  width: 70px;
}

@media only screen and (max-width: 1024px) {
  #abcard {
    width: 100%;
    margin-right: 400px;
  }

  #abcard1-head,
  #abcard2-head,
  #abcard3-head {
    font-size: 70px;
  }

  #card-img-arrow {
    transform: rotate(90deg);
    width: 250px;
  }
}

@media only screen and (max-width: 768px) {
  #abcard {
    width: 100%;
    margin-right: 200px;
  }

  #card-img-arrow {
    transform: rotate(90deg);
    width: 100px;
  }
}

@media only screen and (max-width: 600px) {
  #part-container {
    margin-top: 0px;
  }

  #abcard {
    width: 100%;
    margin-top: -50px;
  }

  #abcard1-head,
  #abcard2-head,
  #abcard3-head {
    font-size: 40px;
  }
  #card-img-arrow {
    transform: rotate(90deg);
    margin-top: -200px;
    width: 70px !important;
    margin-bottom: 20px !important;
  }

  .card-img-top {
    width: 300px !important;
    margin: 0px auto !important;
  }
}

/*---------about Parts  END--------------*/

/*---------Process--------------*/

#process-head {
  font-family: "jenna";
  font-size: 50px;
  font-weight: bolder;
  margin-top: 60px;
  margin-bottom: 40px;
}

#pro {
  margin-top: 30px;
}

#abprocess {
  border: 1px solid #caccd1;
  border-radius: 10px;
  padding: 30px;
}

#headpro .h4 {
  font-size: 35px;
  font-weight: bolder;
  text-align: left;
  font-family: "jenna";
}

#describe .h4 {
  font-size: 16px;
  width: 90%;
  text-align: left;
}

/*---------Process  END--------------*/

/*---------About Middle --------------*/

#ab-middle {
  margin-top: 60px;
}

#ab-middle-img {
  margin-top: 6vw;
  margin-left: 15vw;
  width: 30vw;
}

#abmiddle-overlay {
  margin-left: 50vw;
  margin-top: 10vw;
}

#abmiddle-overlay ul li {
  border: none;
  background-color: transparent;
  font-size: 1.5vw;
}

@media only screen and (max-width: 1024px) {
  #abtop {
    margin-top: 20px;
  }

  #abmiddle-overlay {
    margin-left: 50vw;
    margin-top: 6vw;
  }

  #abmiddle-overlay ul li {
    font-size: 2vw;
  }
}

@media only screen and (max-width: 768px) {
  #abtop {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 600px) {
  #abmiddle-overlay {
    margin-left: 45vw;
    margin-top: 2vw;
  }

  #abmiddle-overlay ul li {
    font-size: 3vw;
    padding-bottom: 0px;
  }

  #ab-middle-img {
    margin-top: 10vw;
    margin-left: 15vw;
    width: 30vw;
  }

  #middle-1 {
    height: 300px;
  }
}

@media only screen and (max-width: 450px) {
  #abmiddle-overlay {
    margin-left: 40vw;
    margin-top: -3vw;
  }

  #abmiddle-overlay ul li {
    font-size: 3vw;
    padding-bottom: 0px;
  }

  #ab-middle-img {
    margin-top: 12vw;
    margin-left: 10vw;
    width: 30vw;
  }

  #middle-1 {
    height: 200px;
  }
}

/*---------about Middle  END--------------*/

/*---------about Quiz Panal  --------------*/

#about-lap-img {
  text-align: center;
  position: relative;
  margin-top: 50px;
  width: 50vw;
}

#ab-quiz-btn {
  background-color: #ff9676;
  text-decoration: none;
  color: white;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 10px;
}

#ab-quiz-btn:hover {
  box-shadow: 3px 5px 10px grey;
}

@media only screen and (max-width: 450px) {
  #about-lap-img {
    width: 60vw;
  }
}

.arrow-about {
  margin-top: 120px;
  @media only screen and (max-width: 800px) {
    margin-top: 30px;
  }
  text-align: center;
}
.text-inf {
  font-size: 14px;
  font-weight: 580;
}

.tab-pane {
  padding: 20px !important;
}
/*---------about Quiz Panal  END--------------*/

.process-text {
  font-size: 23px;
  font-weight: bold;
  font-family: "koho-italic"
}

/*Moblie Size*/

@media only screen and (max-width: 800px) {
  .process-no {
    font-size: 17px !important;
    line-height: 4.3;
  }
  #home-tab,
  #profile-tab,
  #contact-tab {
    font-size: 10px;
    line-height: 2.5;
    background-size: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-inf {
    font-size: 20px;
    line-height: 1.5;
  }

  .process-text {
    font-size: 60px;
    margin-bottom: 10px;
  }

  .card-img-top {
    width: 300px !important;
    margin: 0px auto;
    &#card-img-arrow {
      width: 100px !important;
    }
  }

  .chart-scroll {
    margin-left: 0px;
  }

  .img-about {
    margin-left: 0px !important;
  }
  .plus-minus {
    position: absolute !important;
    margin-left: -0px !important;
    margin-top: -16px;
  }
  .mb-mt-3 {
    margin-top: 20px !important;
  }
  .max-width {
    max-width: 100% !important;
    flex: 0 0 100% !important;
  }
  .widht40 {
    max-width: 40% !important;
    flex: 0 0 40% !important;
  }

  .widht60 {
    max-width: 60% !important;
    flex: 0 0 60% !important;
  }
  .b-top {
    border-bottom: 1px solid #f1f1f1;
    padding-bottom: 10px;
  }
  .user-img {
    width: 30px !important;
    height: 30px !important;
    border: 0px !important;
  }
  .product-img {
    width: 100% !important;
    height: 160px;
    background-size: cover;
    background-position: center;
  }

  .button-div {
    padding: 0px;
    width: 100%;
    text-align: center;
    margin: auto;
    font-size: 10px;
  }
  .button-div button {
    font-size: 12px;
    margin-bottom: 8px;
  }
  .product-txt i {
    font-size: 12px;
    color: black;
  }
  .product-txt h6 {
    font-size: 14px;
    font-size: 14px !important;
    margin-bottom: 5px;
    height: 32px;
  }
  .product-txt {
    padding: 6px;
  }

  .product-txt .price s,
  .product-txt .price span {
    font-size: 14px;
  }

  .btn-match {
    width: 50px;
    height: 50px;
    padding: 2px;
    font-size: 10px !important;
    text-align: center;
    color: white !important;
    border-radius: 100px !important;
    background-color: #f47646 !important;
  }
  .wid-30 {
    max-width: 25% !important;

    flex: 0 0 25% !important;
  }
  .wid-60 {
    max-width: 70% !important;

    flex: 0 0 70% !important;
  }
  .btn-seemore {
    margin-top: 0px;
    font-size: 14px !important;
  }
  .btn-recom {
    margin-top: 100px;
  }
  .wid-100 {
    width: 5% !important;
    flex: 0 0 5% !important;
  }
  #col-6-mine {
    padding-right: 0px;
  }
  #col-special {
    text-align: center !important;
  }

  .profile-title {
    font-size: 40px;
    margin-bottom: 0px;
  }
  .img-polution {
    width: 190px;
  }
  .img-skin {
    width: 160px;
  }
  .radiotextsty {
    font-size: 15px;
  }

  .checkbox-custom,
  .checkbox-custom-label,
  .radio-custom,
  .radio-custom-label {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 700px) {
  .mb-mobile {
    margin-top: 0px;
  }

  .process-no {
    font-size: 20px !important;
    line-height: 4.3;
  }
  #home-tab,
  #profile-tab,
  #contact-tab {
    font-size: 10px;
    line-height: 2.5;
  }

  #card-img-arrow {
    margin-top: 20px !important;
  }

  .hover-btn {
    margin-top: 40px;
  }

  .arrow-about {
    margin-top: 0px !important;
  }
  .btn-facebook,
  .btn-google {
    width: 100% !important;
  }

  form .svgContainer {
    height: 250px !important;
    width: 200px !important;
    margin: 0px auto -2.8em !important;
  }

  .login-cont {
    margin: 0px auto;
    width: 100%;
  }

  .signup-cont {
    margin: 0px auto;
    width: 100%;
  }
  .profile-navs {
    height: 90px;
    background-color: transparent;
    border-radius: 0px !important;
    box-shadow: none;
  }
  .profile-navs li a {
    height: 40px;
    text-align: center;
    line-height: 2.6;
    font-size: 14px;
    font-weight: 600;
    border-bottom: 2px solid #eeeeee !important;
    color: #19354b;
    border-radius: 0px !important;
    text-transform: capitalize;
    background-color: white;
  }
  .padd-10 {
    padding: 10px !important;
  }

  .profile-navs li a i {
    font-size: 14px;

    margin-right: 3px;
  }

  .btn-heart {
    width: 30px;
    height: 30px;
    font-size: 15px !important;
    text-align: center;
    color: #f47545 !important;
    border-radius: 100px !important;
    background-color: #dadada !important;
    padding-top: -10px !important;
    box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  }
  .btn-heart i {
    margin-left: -4px !important;
    margin-top: -5px !important;
  }
  .img-orders {
    width: 40px;
  }

  .tb-my tbody tr td {
    font-size: 14px;
    color: #232530;
    line-height: 2 !important;
    border-bottom: 1px solid #eeeeee !important;
    padding: 0 !important;
  }

  .qty-orders {
    margin-top: 0px !important;
  }
  .pro-title {
    width: 140px;
  }

  .make-center {
    margin: 0px auto !important;
  }

  .widht40,
  .wid-60,
  .wid-30 {
    max-width: 100% !important;

    flex: 0 0 100% !important;
  }

  .widht60 {
    max-width: 100% !important;

    flex: 0 0 100% !important;
  }
  .wid-100 {
    width: 100% !important;
    flex: 0 0 100% !important;
  }
  #col-6-mine {
    padding-right: 15px;
  }
  .btn-recom {
    margin-top: 0px;
  }
  /*.btn-seemore
          {
            display: none !important;

          }
          #col-6-mine
          {
            display: none !important;
          }*/
  .mb-5-mine {
    margin-bottom: 20px !important;
  }
  .mb-show {
    display: block !important;
  }

  .tb-my thead tr th {
    font-size: 12px;
  }
}

.searchDropDown {
  position: absolute;
  width: 240px;
  min-height: 50px;
  background: white;
  top: 51px;
  z-index: 1;
  margin-left: 1px;
  transition: 0.3s;
  border-radius: 5px;
  box-shadow: 2px 2px 19px 0 #00000059;
  padding-top: 18px;
  text-align: center;
}
#carouselExampleControls .carousel-indicators {
  justify-content: center !important;
}
.mobSkinCare {
  display: none !important;
}
.webSkinCare {
  display: inline-block !important;
}
@media only screen and (max-width: 991px) {
  .mobSkinCare {
    display: inline-block !important;
  }
  .webSkinCare {
    display: none !important;
  }
}

.cartNumber {
  position: absolute;
  width: 18px;
  height: 18px;
  background: #f47545;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  // margin-top: -28px;
  margin-left: 9px;
  color: white;
  top: -12px;
}

.cartIconDiv {
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
}

.team .active {
  opacity: 1 !important;
}

.navSearchInput {
  width: 40% !important;
  font-family: "montserrat-medium";
  @media only screen and (max-width: 991px) {
    width: 100% !important;
    margin: auto !important;
  }
}

.navSearchInput input {
  border-radius: 50px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding: 6px 12px !important;
  height: 34px !important;
  font-size: 13px;
}

/* .profileToggleDropdown{
    position: absolute;
    width: 200px;
    height: 200px;
    background: white;
    right: 0;
    top: 40px;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.363);
    opacity: 0;
    transition: 0.3s;
}

.profileToggleDropdownOpen{
    position: absolute;
    width: 200px;
    height: 200px;
    background: white;
    right: 0;
    top: 40px;
    border-radius: 5px;
    box-shadow: 1px 1px 20px 0 rgba(0, 0, 0, 0.363);
    opacity: 1;
}
 */

.dropbtn2 {
  /* background-color: #3498DB; */
  border: none;
  cursor: pointer;
}

.dropbtn2:hover,
.dropbtn2:focus {
  /* background-color: #2980B9; */
}

.dropdown2 {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
}

.dropdown2:hover {
  background: transparent;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 40px;
  border-radius: 5px;
}
@media only screen and (max-width: 768px) {
  .dropdown-content {
    left: 0;
    top: 30px;
  }
  .cartNumber {
    // padding-right: 15px;
    text-indent: 0;
  }
}

.dropdown-content a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown2 a:hover {
  background-color: #ddd;
}

.show {
  display: block;
}

.validateLable {
  font-size: 12px;
  color: red;
}

.recommendationDivInside {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
  }
}
.catNameButton {
  background-color: #19354b !important;
  padding: 5px !important;
  width: 170px;
  font-size: 14px;
  color: white;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  border: none;
  border-radius: 5px;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
  }
}
.catNameButton:hover {
  transform: scale(1.05);
  transition: 0.2s all;
}
@media only screen and (max-width: 600px) {
  .recommendationDivInside {
    flex-direction: column;
  }
  .catNameButton {
    width: 100%;
    padding: 10px !important;
    margin-bottom: 10px;
  }
  .recommendationDivInside .product {
    width: 50%;
  }
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 9999;
  box-shadow: 2px 2px 15px 0 rgba(0, 0, 0, 1);
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: black;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  font-size: 36px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #818181;
  opacity: 0;
  transition: 0.5s;
}
.sidenav .closebtn img {
  width: auto;
  height: 50px;
}

.sidenav .closebtn span {
  font-size: 40px;
  font-family: jenna sue;
  width: 90px;
  font-weight: 800;
}

.sidenavOpen {
  width: 400px;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
  .sidenavOpen {
    width: 100%;
  }
}

@media screen and (max-width: 450px) {
  .sidenavOpen {
    width: 100%;
  }
}

.cartItemList {
  margin-top: 50px;
  opacity: 0;
  transition: 0.5s;
}
.cartMain {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
  padding: 20px;
  border-bottom: 1px solid lightgray;
  position: relative;
}
.cartMain .cartImageDiv {
  width: 35%;
}
.cartMain .cartContent {
  width: 65%;
}
.cartMain .cartImageDiv img {
  width: 100px;
}

.cartMain .cartContent h3 {
  font-family: lato;
  font-size: 18px;
  margin: 0;
}
.cartMain .cartContent p {
  font-family: lato;
  color: rgba(0, 0, 0, 0.384);
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cartMain .cartContent p span {
  color: rgba(0, 0, 0, 0.589);
  font-weight: 600;
}

.cartItemList .cartButtonDiv a {
  background: #f47545;
  width: 150px;
  margin: auto;
  font-size: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  margin-top: 20px;
  padding: 5px 20px;
  border-radius: 50px;
}
.cartItemList .cartButtonDiv a:hover {
  transform: scale(1.1);
}

.quizBackMain {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 50px;
  width: 100px;
  align-items: center;
}

.quizBack {
  width: 60px;
  cursor: pointer;
  margin-bottom: 10px;
}

.quizBack2 {
  width: 100px;
  cursor: pointer;
}

.loaderDiv {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-top: 5px;
  .ant-spin-dot-item {
    background-color: #f47545 !important;
  }
}
.searchList {
  z-index: 999999;
  position: absolute;
  top: 50px;
  width: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 1px 1px 15px 0px #00000036;
  padding: 0;
  max-height: 400px;
  overflow-y: scroll;
  padding: 10px;
  b {
    // padding-left: 10px;
    // margin-right: 5px;
    color: #f37545;
  }
  #searchedBrandNav,
  #searchedCategoriesNav {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
  }
  .searchedBrandNav {
    b {
      width: 70px;
    }
  }
  .searchedCategoriesNav {
    b {
      width: 95px;
    }
  }
  .searchedBrandNav,
  .searchedCategoriesNav {
    p {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
    hr {
      margin-top: 5px;
      margin-bottom: 5px;
    }
    a {
      color: black;
      &:hover {
        color: #f37545;
      }
    }
  }
}
.searchList .seachListItem img {
  width: 50px;
  margin-right: 10px;
}
.searchList .seachListItem {
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.searchList .seachListItem:hover {
  background: rgba(0, 0, 0, 0.096);
}
.searchList .seachListItem p {
  margin: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.411);
}

.plusMinus {
  color: black !important;
  font-size: 20px !important;
  cursor: pointer;
  display: inline-block;
}

@media only screen and (max-width: 600px) {
  .first2Open {
    display: block;
  }
}

.totalDiv {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  font-size: 18px;
}

.removeAlert {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  z-index: -1;
  transition: 0.7s;
}

.removeAlertShow {
  opacity: 1;
  z-index: 3;
}

.removeAlert button {
  width: 110px;
  border: none;
  background: #f47545;
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px;
  margin-left: 5px;
  transition: 0.3s;
}
.removeAlert button:hover {
  transform: scale(1.1);
}

.outOfStockAlert {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  z-index: 2;
  transition: 0.9s;
  h1 {
    font-size: 40px;
    word-spacing: 10px;
  }
}

@media only screen and (max-width: 420px) {
  .hoverDiv {
    display: none !important;
  }
}

.searchList::-webkit-scrollbar {
  width: 5px;
}

.searchList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
}

.searchList::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.marginTopDiv {
  @media only screen and (max-width: 600px) {
    // margin-top: 130px;
  }
}

.successDiv {
  width: 100%;
  height: calc(100vh - 430px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.successDiv .success {
  font-size: 80px;
  font-family: jenna sue;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f47545;
  flex-wrap: wrap;
}
.successDiv .success img {
  width: 100px;
  margin-left: 30px;
  margin-bottom: 20px;
}

.profileDrop {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 0;
  top: 40px;
  border-radius: 5px;
  z-index: 99999;
}
.profileDrop.show {
  display: block;
}
.profileDrop a {
  color: black !important;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
@media only screen and (max-width: 768px) {
  .profileDrop {
    left: 0;
    top: 30px;
  }
}

.ipadview {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .mobileTable {
    width: 650px;
  }
}

.productA {
  text-decoration: none;
  color: black;
  outline: none;
}
.productA:hover {
  color: #f47545;
  text-decoration: none;
}

.fa-rupee-sign {
  color: #f47545 !important;
  &.two {
    color: red !important;
  }
  &.three {
    color: black !important;
  }
}

.checkoutOrderP {
  float: left !important;
  color: black !important;
  display: inline-block !important;
  width: 80% !important;
}

.pie_progress {
  width: 160px;
  margin: 10px auto;
}

@media all and (max-width: 768px) {
  .pie_progress {
    width: 80%;
    max-width: 300px;
  }
}

.circle-background,
.circle-progress,
.circle-progress2 {
  fill: none;
}

.circle-background {
  stroke: #ddd;
}

.circle-progress,
.circle-progress2 {
  stroke: #f47545;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: 1s;
}

.upperProgress {
  position: relative;
  margin: 10px 0px;
  .progressText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    b {
      font-size: 30px;
      font-weight: normal;
      display: inline-block;
    }
    small {
      font-size: 12px;
      width: 100%;
      display: inline-block;
    }
  }
}

.circle-text {
  font-size: 3em;
  font-weight: bold;
  fill: red;
}

.navvv {
  .nav-tabs {
    border: none;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    background-image: url("Images/about/background-active.png") !important;
    background-color: transparent !important;
    height: 50px;
  }
  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.cartRemove {
  position: absolute;
  top: 0;
  right: 20px;
  cursor: pointer;

  svg:hover {
    color: #f47545;
  }
}

main {
  // padding-bottom: 60px;
}
.skinCaree {
  padding-bottom: 60px;
  main {
    padding-bottom: 0;
  }
}
.homeee {
  padding-bottom: 60px;
  background-color: white;
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  .navSearchInput {
    margin: auto !important;
  }
}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .page {
    max-width: 100%;
  }
  #carousel-example2 {
    display: none;
  }
  .product-img {
    width: 100%;
  }
  .product-txt h6 {
    width: 100%;
    font-size: 14px;
  }
  .product-txt i {
    font-size: 12px;
  }
}

@media screen and (max-width: 768px) and (min-width: 767px) {
  .cartNumber {
    padding: 0;
  }
}

@media screen and (max-width: 1200px) and (min-width: 998px) {
  .rec-div {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .page {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) and (min-width: 699px) {
  .product-txt h6 {
    width: 100%;
    font-size: 14px;
  }
}

.slider-arrow {
  position: absolute;
  z-index: 1;
  height: auto;
  width: 106%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  i {
    font-size: 34px;
    cursor: pointer;
    &:hover {
      color: #f57645;
    }
  }
}

main {
  min-height: 80vh;
  .skinCaree {
    min-height: 100vh;
    &.profilee{
      min-height: auto;
      padding-bottom: 0;
    }
  }
}

.page h2 {
  text-align: center;
  padding-top: 20px;
  /* background-image: url(./../Images/underline.png); */
  background-position: bottom;
  background-repeat: no-repeat;
  padding-bottom: 0px !important;
  margin-bottom: 0px;
}

.notificationAlert {
  display: none;
  z-index: 999999;
  position: fixed;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  top: 20%;
  transform: translate(0, -20%);
  border: 1px solid transparent;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
}

.product .price {
  display: flex;
  justify-content: space-evenly;
  font-size: 16px !important;
  @media screen and (max-width: 768px) {
    font-size: 14px !important;
    width: 100%;
    display: flex;
    align-items: center !important;
    justify-content: space-between !important;
    flex-direction: row;
    margin: 0;
  }
  span,
  i {
    color: #212529e0 !important;
    font-size: 16px;
    @media screen and (max-width: 800px) {
      font-size: 14px;
    }
  }
  .lightGray {
    color: gray !important;
    font-size: 16px !important;
    display: inline;
    @media screen and (max-width: 800px) {
      font-size: 14px !important;
      &.two {
        // display: none;
      }
    }
    span,
    i {
      color: gray !important;
      font-size: 16px !important;
      @media screen and (max-width: 800px) {
        font-size: 14px !important;
      }
    }
    i {
      font-size: 14px !important;
      @media screen and (max-width: 800px) {
        font-size: 12px !important;
      }
    }
  }
  .pinkk {
    color: #dc3545 !important;
    font-size: 16px !important;
    @media screen and (max-width: 800px) {
      font-size: 14px !important;
      text-transform: lowercase;
    }
  }
}
@media screen and (max-width: 600px) {
  .product-img .hoverDiv {
    // display: none;
  }
}

@media screen and (max-width: 768px) {
  .product-img .hoverDiv {
    display: block !important;
    opacity: 1 !important;
    .hoverI {
      background-color: transparent;
      svg {
        color: #f37545;
        path {
          fill: #f37545;
        }
      }
    }
  }
}

.socialLogins {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  @media screen and (max-width: 600px) {
    justify-content: space-between;
    .signupp {
      font-size: 14px;
      padding: 0 20px;
      width: auto;
      height: 45px;
    }
  }
  span {
    @media screen and (max-width: 600px) {
      // width: 100%;
      margin-bottom: 10px;
    }
    height: 50px;
  }
  .my-facebook-button-class {
    background-color: rgb(255, 255, 255);
    margin-right: 60px;
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
      rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
    padding: 0px;
    border-radius: 2px;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto, sans-serif;
    // padding: 10px;
    position: relative;
    // width: 100%;
    height: 50px;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: rgb(255, 255, 255);
    }
    i {
      font-size: 50px;
      color: #1877f2;
    }
    @media screen and (max-width: 600px) {
      margin: 0;
    }
  }
  .googleButton {
    @media screen and (max-width: 600px) {
      // width: 100%;
    }
    height: 50px;
    margin-right: 60px;
    box-shadow: none !important;
    @media screen and (max-width: 600px) {
      margin: 0;
      span {
        margin: 0;
      }
    }
    img {
      width: 45px;
    }
    span {
      padding: 0 !important;
    }
  }
}
.outOfStockDiv {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translate(-50%, -95%);
  background-color: #f47545;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  width: 70%;
  text-align: center;
  font-weight: bold;
  box-shadow: 2px 2px 6px 0px rgb(0, 0, 0);
  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.ant-pagination {
  margin-left: auto !important;
  .ant-pagination-item {
    a {
      text-align: center !important;
    }
  }
  .ant-pagination-item-link {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .ant-pagination-jump-next-custom-icon,
  .ant-pagination-jump-prev-custom-icon {
    &:hover {
      color: black !important;
      .ant-pagination-item-ellipsis {
        opacity: 1 !important;
      }
    }
    svg {
      display: none;
    }
    .ant-pagination-item-ellipsis {
      position: relative !important;
      display: inline-block !important;
      opacity: 1 !important;
    }
  }
}

html {
  // scroll-behavior: smooth;
}
.ProfileNumberError {
  font-size: 14px !important;
  color: red;
  text-align: left !important;
  font-family: "lato" !important;
}
.skinCaree .page {
  @media screen and (min-width: 1200px) {
    max-width: 1200px;
  }
  &.recommendation {
    max-width: 1366px;
  }
}

.orderMainFlex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  .orderMain {
    h6 {
      font-size: 16px;
      font-weight: bold;
      color: grey;
      margin: 0;
    }
    p {
      font-weight: bold;
      margin-bottom: 15px;
    }
    h5 {
      font-weight: bold;
      margin: 0;
    }
    .orderSmallImages {
      display: flex;
      align-items: center;
      .singleImageDiv {
        width: 60px;
        height: 60px;
        position: relative;
        margin-right: 5px;
        p {
          position: absolute;
          height: 20px;
          width: 20px;
          text-align: center;
          font-size: 12px;
          background-color: rgba(128, 128, 128, 0.5);
        }
      }
    }
  }
  .orderDetailsBut {
    border: 0;
    color: #f47545;
    background-color: white;
    border: 1px solid #f47545;
    border-radius: 5px;
    padding: 5px 10px;
  }
  .orderDetailsButClose {
    border: 0;
    color: #f47545;
    background-color: white;
    border: 1px solid #f47545;
    border-radius: 5px;
    padding: 5px 10px;
    display: none;
  }
}
.orderMainFlex {
  hr {
    display: none;
  }
  .card {
    z-index: 0;
    background-color: white;
    // padding-bottom: 20px;
    margin-top: 0px;
    // margin-bottom: 10px;
    border-radius: 10px;
    display: none;
  }
  .orderSmallImages {
    display: flex;
    align-items: center;
    .singleImageDiv {
      width: 60px;
      height: 60px;
      position: relative;
      margin-right: 5px;
      p {
        position: absolute;
        height: 20px;
        width: 20px;
        text-align: center;
        font-size: 12px;
        background-color: rgba(128, 128, 128, 0.5);
      }
    }
  }
  .top {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 13% !important;
    padding-right: 13% !important;
  }

  #progressbar {
    margin-bottom: 10px;
    overflow: hidden;
    color: #455a64;
    padding-left: 0px;
    margin-top: 30px;
  }

  #progressbar li {
    list-style-type: none;
    font-size: 13px;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
  }

  #progressbar .step0:before {
    font-family: FontAwesome;
    content: "\f10c";
    color: #fff;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -ms-transition: all 0.2s;
    -o-transition: all 0.2s;
  }

  #progressbar li:before {
    width: 40px;
    height: 40px;
    line-height: 45px;
    display: block;
    font-size: 20px;
    background: #f47445b2;
    border-radius: 50%;
    margin: auto;
    padding: 0px;
  }

  #progressbar li:after {
    content: "";
    width: 100%;
    height: 6px;
    background: #f4744557;
    position: absolute;
    left: 0;
    top: 16px;
    z-index: -1;
  }

  #progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    left: -50%;
  }

  #progressbar li:nth-child(2):after,
  #progressbar li:nth-child(3):after {
    left: -50%;
  }

  #progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: 50%;
  }

  #progressbar li:last-child:after {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  #progressbar li:first-child:after {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  #progressbar li.active:before,
  #progressbar li.active:after {
    background: #f47545;
  }

  #progressbar li.active:before {
    font-family: FontAwesome;
    content: "\f00c";
  }

  .icon {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .icon-content {
    padding-bottom: 20px;
  }

  @media screen and (max-width: 992px) {
    .icon-content {
      width: 50%;
    }
  }
}

.scoreDiv {
  align-items: center;
  justify-content: center;
  .match {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.afiliateMarketList {
  margin-top: -50px;
  &.quickViewAff {
    margin-top: 0;
  }
  .offerLinks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(128, 128, 128, 0.274);
    padding: 10px 0;
    p {
      margin: 0;
      font-size: 18px;
      color: #f47545;
      i {
        font-size: 14px !important;
      }
    }
    b {
      width: 30%;
      font-size: 18px;
      text-transform: capitalize;
    }
    .offerCode {
      width: 100%;
      font-size: 12px;
      span {
        // font-weight: bold;
        color: #f47545;
      }
    }
    button {
      max-width: 203px;
      background-color: #f47545;
      color: #fff;
      width: 100px;
      padding: 0px;
      line-height: 30px;
      border: none;
      border-radius: 5px;
      transition: 0.3s;
      transform: scale(0.9);
      &:hover {
        transform: scale(1);
      }
    }
  }
  &.quickViewAff {
    float: right;
    height: 300px;
    overflow-y: scroll;
  }
  @media screen and (max-width: 768px) {
    margin-top: 0;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      border-bottom: 1px solid rgba(128, 128, 128, 0.274);
    }
    th {
      padding: 10px;
      width: 45%;
      font-size: 18px;
      text-transform: capitalize;
    }
    td {
      &.pricee {
        text-align: left;
      }
      text-align: center;
      color: #f47545;
      button {
        max-width: 203px;
        background-color: #f47545;
        color: #fff;
        width: 100px;
        padding: 0px;
        line-height: 30px;
        border: none;
        border-radius: 5px;
        transition: 0.3s;
        transform: scale(0.9);
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
}

.afiliateMarketList::-webkit-scrollbar {
  width: 5px;
}

.afiliateMarketList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
}

.afiliateMarketList::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.resSpanWeb {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 992px) {
    display: none;
  }
  @media screen and (max-width: 768px) {
    align-items: flex-start;
  }
}

.resSpanMob {
  @media screen and (max-width: 992px) {
    display: none;
  }
}

.redirectPage {
  height: 100vh;
  width: 100%;
  background-color: white;
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .redirectPageImage1 {
    width: 200px;
  }
  .redirectPageImage2 {
    width: 200px;
  }
  h1 {
    display: block;
    font-size: 50px;
    margin-top: 30px;
    margin-right: 30px;
  }
  .ant-spin-dot-item {
    background-color: #f37646;
  }
}
.crossIcon {
  position: absolute;
  // margin-left: 20px;
  // margin-top: 20px;
  z-index: 99999;
  left: 20px;
  i {
    cursor: pointer;
    &:hover {
      color: #f37646;
    }
    font-size: 40px;
  }
}

.summaryMain {
  padding: 30px;
  background-color: white;
  height: auto;
  @media screen and (max-width: 768px) {
    height: auto;
  }
  .btn-login {
    width: 280px !important;
    height: 50px;
  }
  .summaryBorderDiv {
    // border: 1px solid #ff9676;
    width: 100%;
    height: auto;
    padding: 30px 100px;
    @media screen and (max-width: 768px) {
      height: auto;
      padding: 10px;
    }
    .hiText {
      text-align: center;
      font-style: italic;
      color: #19354b;
      font-size: 16px;
    }
    .summaryTable {
      margin: auto;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      &.summaryTableTwo {
        display: none;
      }
      @media screen and (max-width: 768px) {
        width: 100%;
        display: none;
        &.summaryTableTwo {
          display: block;
          border-collapse: collapse;
          tr {
            display: inline-table;
            width: 100%;
          }
          th,
          td {
            width: 50%;
            text-align: left;
          }
        }
      }
      td {
        // width: 50%;
        font-weight: 600;
        color: #19354b;
        height: 30px;
        font-size: 16px;
      }
      th {
        // width: 50%;
        color: #f37545;
        font-weight: 600;
        height: 30px;
        font-size: 16px;
      }
    }
    .skinSummary {
      margin-top: 50px;
      padding: 30px 80px;
      background-color: #19354b0a;
      @media screen and (max-width: 768px) {
        padding: 20px;
      }
      h5 {
        font-size: 14px;
        font-weight: 800;
        color: #19354b;
      }
      p {
        font-size: 14px;
        color: #19354b;
        margin: auto;
      }
    }
    .strengthWeak {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      div {
        width: 48%;
        padding: 30px 80px;
        @media screen and (max-width: 768px) {
          width: 100%;
          padding: 20px;
          margin-top: 10px;
        }
        h5 {
          font-size: 14px;
          font-weight: 800;
          color: #19354b;
        }
        p {
          font-size: 14px;
          color: #19354b;
          margin: auto;
        }
      }
      .strength {
        background-color: #ff96761c;
      }
      .weak {
        background-color: #19354b15;
      }
    }
    .editableDiv {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
      .first,
      .second {
        width: 48%;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 768px) {
          flex-wrap: wrap;
        }
        .div {
          width: 50%;
          @media screen and (max-width: 768px) {
            width: 100%;
            margin-top: 20px;
          }
        }
        .badgessMain {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          .badgess {
            padding: 8px 13px;
            margin-right: 5px;
            font-size: 12px;
            flex-wrap: wrap;
            background-color: #19354b;
            color: white;
            border-radius: 50px;
          }
        }
      }
      h5 {
        font-size: 14px;
        color: #19354b;
        font-weight: bold;
        i {
          margin-left: 10px;
          font-size: 12px;
          color: rgba(128, 128, 128, 0.452);
          cursor: pointer;
        }
      }
    }
  }
}
.skinConcernimages {
  .btn-primary {
    border-width: 0 !important;
    background-color: transparent !important;
    @media screen and (max-width: 900px) {
      padding: 5px !important;
    }
    &.selected {
      @media screen and (max-width: 900px) {
        border: 2px solid rgb(255, 150, 118);
        padding: 5px !important;
      }
    }
  }
  img {
    width: 180px;
    margin-right: 10px;
    border: 2px solid transparent;
    border-radius: 20px;
    margin-top: 5px;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      width: 130px;
    }
    &:hover {
      //   border: 2px solid #FF9676;
    }
  }
}

#root .blogDetailsMain #blog-p p {
  font-weight: 1900 !important;
}

.ant-drawer {
  z-index: 99999 !important;
  .ant-drawer-body {
    padding: 0;
  }
  .ant-collapse {
    background-color: white !important;
  }
  .ant-collapse-content-box {
    padding-top: 8px !important;
  }
  .dropdown-item.active {
    background-color: transparent !important;
    color: black;
  }
  .dropdown-item {
    &:active {
      background-color: transparent;
      color: black;
    }
  }
  .ant-collapse-header {
    font-weight: 600;
    svg {
      color: black !important;
    }
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      color: #ff9676 !important;
    }
  }
  .quiz {
    // margin: auto;
    // margin-top: 10px;
  }
  .ant-drawer-header {
    padding-right: 10px;
    padding-left: 10px;
  }
  .nestedCate {
    display: flex;
    flex-wrap: wrap;
    a {
      width: 50%;
      white-space: normal;
      margin: 0;
      padding: 0;
      background: white;
      color: black;
    }
  }
}

.antDrawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  div {
    width: 50%;
    display: flex;
    justify-content: left;
    align-items: center;
    border-right: 1px solid rgba(128, 128, 128, 0.185);
    &.div {
      border: none;
      justify-content: center;
      svg {
        margin-right: 10px;
        font-weight: bold;
      }
    }
  }
}

.antdDrawerWeb {
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 991px) {
    display: none;
  }
}
.mob-btn {
  display: none;
}

.mob-btn {
  @media only screen and (max-width: 800px) {
    display: block;
    display: flex;
  }
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.mob-cart,
.mob-wish {
  width: 50%;
}
.mob-wish {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.mob-btn button {
  background-color: transparent;
  color: #193548;
  border: none;
  padding: 0;
}
.mob-btn .button-div svg {
  fill: black;
  color: black;
}
.mob-btn p {
  font-size: 8px;
  font-weight: 600;
}
.mob-btn svg {
  font-size: 15px;
}

.curate {
  &.curate2 {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: none;
    &.curate2 {
      display: block;
      #carouselExampleSlidesOnlyy {
        height: 450px;
      }
      .progres-div {
        width: 80%;
        margin: auto;
      }
      .match {
        width: 80%;
        margin: auto !important;
      }
      .controlProduct {
        width: 100%;
        .btn {
          padding-right: 0;
          padding-left: 0;
        }
      }
      .cream {
        margin: auto;
        width: 80%;
      }
    }
  }
}

.navbar {
  .profileIcon {
    padding: 0 !important;
    svg {
      fill: #19354b;
      font-size: 25px;
      @media only screen and (max-width: 768px) {
        font-size: 20px;
      }
    }
    i{
      color: #19354b;
      font-size: 25px;
    }
  }
}

.searchPagination {
  display: flex;
  align-items: center;
  justify-content: right;
}

.fa-rupee-sign {
  font-size: 14px !important;
  margin-right: 2px;
  @media only screen and (max-width: 768px) {
    font-size: 12px !important;
  }
}
.newDropDownList {
  background: white;
  width: 200px !important;
  padding: 0.5rem 10px;
  background-clip: padding-box;
  border: 2px solid #f47545;
  border-radius: 0.25rem;
  position: absolute;
  transition: 0.4s;
  text-align: left;
  #newDropDownList-menu {
    width: 100%;
  }
  div {
    padding: 3px 0px;
  }
  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
    transition: 0.5s;
    padding: 5px;
    &:hover {
      color: #f47545;
      transition: 0.5s;
      padding-left: 10px;
    }
  }
}
.newDropDownListOne {
  @media only screen and (max-width: 768px) {
    width: 80% !important;
  }
}

.termsDiv {
  padding: 50px;
  width: 80%;
  margin: auto;
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
  h1 {
    font-size: 40px;
    text-align: center;
    font-family: "Times New Roman", Times, serif;
  }
  h2 {
    font-size: 20px !important;
    font-family: "FontAwesome";
  }
  p {
    font-size: 18px;
    text-align: justify;
    font-family: "FontAwesome";
  }
  ul {
    font-size: 18px;
    text-align: justify;
    font-family: "FontAwesome";
  }
  ol {
    font-size: 18px;
    text-align: justify;
    font-family: "FontAwesome";
  }
}

#wrapper {
  font-family: Lato;
  font-size: 1.5rem;
  text-align: center;
  box-sizing: border-box;
  color: #333;

  a,
  area,
  button,
  [role="button"],
  input:not([type="range"]),
  label,
  select,
  summary,
  textarea {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  #dialog {
    // border: solid 1px #ccc;
    // margin: 10px auto;
    // padding: 20px 30px;
    // display: inline-block;
    // box-shadow: 0 0 4px #ccc;
    // background-color: #faf8f8;
    // overflow: hidden;
    // position: relative;
    // max-width: 450px;

    h3 {
      margin: 0 0 10px;
      padding: 0;
      line-height: 1.25;
    }

    span {
      font-size: 90%;
    }

    #form {
      max-width: 100%;
      margin: 25px auto 0;
      //   display: flex;
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      input {
        margin: 0 5px;
        // height: 60px;
        text-align: center;
        line-height: 80px;
        font-size: 40px;
        border: solid 1px #ccc;
        box-shadow: 0 0 5px #ccc inset;
        outline: none;
        width: 45px;
        transition: all 0.2s ease-in-out;
        border-radius: 3px;

        &:focus {
          border-color: #f47545;
          box-shadow: 0 0 5px #f47545 inset;
        }

        &::selection {
          background: transparent;
        }
        @media only screen and (max-width: 768px) {
          margin: 0 5px;
          text-align: center;
          line-height: 45px;
          font-size: 35px;
          border: solid 1px #ccc;
          box-shadow: 0 0 5px #ccc inset;
          outline: none;
          width: 45px;
          transition: all 0.2s ease-in-out;
          border-radius: 3px;
          padding: 5px 0;
        }
      }

      button {
        margin: 30px 0 50px;
        width: 140px;
        border-radius: 50px !important;
        padding: 6px;
        background-color: #f47445;
        border: none;
        text-transform: uppercase;
      }
    }

    button {
      &.close {
        border: solid 2px;
        border-radius: 30px;
        line-height: 19px;
        font-size: 120%;
        width: 22px;
        position: absolute;
        right: 5px;
        top: 5px;
      }
    }

    div {
      position: relative;
      z-index: 1;
      font-size: 15px;
    }
    p {
      font-size: 20px;
      font-weight: bold;
    }
    img {
      position: absolute;
      bottom: -70px;
      right: -63px;
    }
    .splitter {
      padding: 0 5px;
      color: #f47445;
      vertical-align: super;
      font-size: 24px;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}
#mainHead {
  margin-bottom: 20px;
}

.aboutTab {
  @media screen and (max-width: 800px) and (min-width: 600px) {
    height: 31px !important;
  }
  @media screen and (max-width: 600px) {
    height: 60px !important;
  }
  height: 50px !important;
  &:hover {
    border-color: transparent !important;
  }
  span {
    line-height: normal !important;
  }
  &.active {
    color: white !important;
  }
}

.about-tabs li a:hover {
  border-color: transparent !important;
}

.cossmetifyFooter {
  display: flex;
  justify-content: space-evenly;
  padding: 15px 0;
  flex-wrap: wrap;
  ul {
    list-style: none;
    text-align: center;
    margin: 0;
    @media screen and (max-width: 600px) {
      margin: 20px;
    }
    .cossmetifyFooterUlHead {
      margin: 0 0 0.1rem;
      font-size: 0.9rem;
      text-align: center;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 18px;
      font-family: "FontAwesome";
      &:hover {
        color: white !important;
        font-size: 18px !important;
      }
    }
    li {
      font-size: 16px;
      font-family: cursive;
      a {
        color: white !important;
        &:hover {
          color: #f37545 !important;
          cursor: pointer;
        }
      }
      &:hover {
        color: #f37545;
        cursor: pointer;
        font-size: 16.5px;
      }
    }
  }
}
.ViewAllMobLink {
  color: #f37545 !important;
  font-size: 100%;
  width: 100%;
  text-align: center;
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
}

.carousel .price {
  justify-content: center !important;
  span {
    margin-right: 5px;
    span {
      margin: 0;
    }
  }
}

.mob-btn .price {
  justify-content: center !important;
  margin-top: 10px;
  width: 100%;
  align-items: center !important;
  span {
    margin-right: 2px;
    span {
      margin: 0;
    }
  }
  .lightGray {
    span {
      font-size: 11px !important;
      i {
        font-size: 9px !important;
      }
    }
  }
}

.firstDiv {
  @media screen and (max-width: 600px) {
    .startQuiz {
      justify-content: center;
      button {
        margin-right: 0;
      }
    }
  }
}

.navBrandsBlogs {
  font-size: 14px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
  scroll-behavior: smooth;
  box-sizing: border-box;
  touch-action: manipulation;
  font-weight: 600;
  position: relative;
  padding: 12px 16px;
  padding-left: 40px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.5715;
  cursor: pointer;
  transition: all 0.3s, visibility 0s;
  a {
    color: rgba(0, 0, 0, 0.85);
    &:hover {
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
