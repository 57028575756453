* {
  scroll-behavior: smooth;
}
.brands {
  h2 {
    text-align: center;
  }
  .searchbrandsinput {
    border: 0;
    font-size: 16px;
    line-height: 35px;
    letter-spacing: 1px;
    width: 85%;
    padding: 0 15px;
    text-transform: capitalize;
    color: #6c7a82;
    outline: none;
  }
  .itemBtn {
    font-size: 16px;
    color: #2c414c;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 50%;
    -webkit-transition: background-color 0.5s;
    transition: background-color 0.5s;
    border: none;
    margin-right: 2px;
  }
  .itemBtnActive,
  .itemBtn:hover {
    background-color: #f47545b0;
  }
  .brt1s {
    border-width: 0px;
    border-style: solid;
    border-color: #c1bfda;
    box-shadow: 5px 4px 23px -16px;
  }
  .brr1s {
    border-right-width: 1px;
    border-right-style: solid;
    border-color: #c1bfda;
    @media screen and (max-width: 600px) {
      border: 2px solid #c1bfda;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      margin-bottom: 10px;
    }
  }
  .bg-white {
    background-color: white !important;
    padding: 15px 0px !important;
  }

  .all-brnads-pg {
    position: relative;
    margin: 30px 0 60px;
  }
  .alphaCar {
    height: 60px;
    width: 60px;
    line-height: 60px;
    display: inline-block;
    text-align: center;
    text-transform: uppercase;
    border-radius: 50%;
    font-size: 24px;
    color: #2c414c;
    position: absolute;
    top: -30px;
    left: 20px;
    background-color: #f47545b0;
  }
  .brandscategory {
    padding: 40px 20px 20px;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
  }
  .brandscategory li {
    cursor: pointer;
    min-width: 150px;
    margin: 10px 2px;
    width: 20%;
  }
  a {
    text-decoration: none;
    color: black;
  }
  .gotoTopBtn {
    display: inline-block;
    width: 60px;
    height: 60px;
    background-color: #647881;
    color: #fff;
    line-height: 60px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 30px;
    border-radius: 50%;
    position: fixed;
    bottom: 150px;
    right: 10px;
    z-index: 100;
    border: none;
    outline: none;
  }
}

.brandsSearchDropDown {
  width: calc(100% - 30px);
  margin-left: -15px;
  overflow-y: scroll;
  max-height: 200px;
  background-color: white;
  border: 2px solid rgba(128, 128, 128, 0.171);
  border-radius: 5px;
  position: absolute;
  z-index: 9;
  display: flex;
  flex-direction: column;
  transition: 0s;
  border-radius: 0;
  border-top: 0;
  margin-left: 20px;
  @media screen and (max-width: 600px) {
    width: calc(100% - 35px);
  }
  a {
    padding: 5px 10px;
    // border-bottom: 1px solid rgba(128, 128, 128, 0.171);
    &:hover {
      background-color: rgba(128, 128, 128, 0.171);
      color: black;
    }
  }
  p {
    font-size: 12px;
    font-weight: bold;
    padding-top: 20px;
    padding-left: 10px;
  }
}

.brandsSearchDropDown::-webkit-scrollbar {
  width: 5px;
}

.brandsSearchDropDown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.13);
}

.brandsSearchDropDown::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.brandsPage {
  @media screen and (max-width: 600px) {
    margin-top: 20px !important;
  }
  h2 {
    @media screen and (max-width: 600px) {
      margin-top: 0px !important;
      margin-bottom: 0px !important;
    }
  }
}
