.quickViewModel {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  .profilee {
    position: relative;
    .seconddd {
      padding: 20px;
    }
    .crossIcon {
      position: absolute;
      left: 35px;
      top: 25px;
      z-index: 9999;
      font-size: 25px;
      color: #f47545;
      cursor: pointer;
    }
    .preview {
      min-width: 33.33%;
    }
    .checkout-page {
      width: 1120px;
    }
  }
}

.detailWhishlistIcon {
  cursor: pointer;
  margin-right: 10px;
  font-size: 20px;
  &:hover {
    color: #f47545;
  }
  &.bi-heart-fill {
    fill: #f47545;
  }
}
.quickViewQuizButton {
  min-width: 290px;
  font-size: 20px;
  height: 50px;
  margin-left: -14px;
  background-color: #f47545;
  color: #fff;
  width: 100px;
  padding: 10px 0px;
  // line-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  transition: 0.3s;
  -webkit-transform: scale(0.9);
  transform: scale(0.9);
  @media screen and (max-width: 600px) {
    margin: 0;
    margin-left: auto;
    margin-right: auto;
  }
}
.QuizBtnText {
  font-size: 13px;
  letter-spacing: 1px;
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-top: 30px;
  }
}
