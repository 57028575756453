.contactUsMain {
  padding-bottom: 50px;
  @media screen and (max-width: 600px) {
    padding-bottom: 0;
    overflow: hidden;
  }
  .mainImg {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;
    @media screen and (max-width: 600px) {
        width: 300%;
        position: relative;
        left: -500px;
        margin-top: 0;
    }
  }
  h6 {
    font-size: 18px;
    font-weight: bold;
  }
  .ant-collapse {
    background-color: transparent;
    border: none;
    .ant-collapse-item {
      border-width: 3px;
      .ant-collapse-header {
        font-weight: 600;
        font-size: 16px;
        .ant-collapse-arrow {
          top: 7px;
        }
        @media screen and (max-width: 600px) {
          padding: 12px 20px;
          .ant-collapse-arrow {
            left: 0;
            top: 7px;
          }
        }
      }
      .ant-collapse-content-box {
        @media screen and (max-width: 600px) {
          padding: 0;
        }
      }
      .ant-collapse-content {
        background-color: transparent;
        .content_p {
          font-size: 16px;
        }
        .content_form {
          width: 100%;
          padding: 25px;
          background-color: white;
          form {
            width: 50%;
            margin: auto;
            @media screen and (max-width: 600px) {
              width: 100%;
            }
            .inputDiv {
              width: 100%;
              display: flex;
              flex-direction: column;
              label {
                display: inline-block;
                margin-bottom: 0.3rem;
                font-weight: 600;
                text-transform: uppercase;
                letter-spacing: 0.025rem;
                font-size: 16px;
                margin: 0;
              }
              input {
                display: block;
                width: 100%;
                height: 2.5rem;
                padding: 0.375rem 0.375rem 0.375rem 0.75rem;
                color: #000;
                background: #eeeeee;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                font-size: 1rem;
                font-family: inherit;
                outline: none;
                &:focus {
                  color: #000;
                  background: #eeeeee;
                  border-color: #80bdff;
                  outline: 0;
                }
              }
              textarea {
                display: block;
                width: 100%;
                padding: 0.375rem 0.375rem 0.375rem 0.75rem;
                color: #000;
                background: #eeeeee;
                background-clip: padding-box;
                border: 1px solid #ced4da;
                border-radius: 0;
                transition: border-color 0.15s ease-in-out,
                  box-shadow 0.15s ease-in-out;
                font-size: 1rem;
                font-family: inherit;
                outline: none;
                &:focus {
                  color: #000;
                  background: #eeeeee;
                  border-color: #80bdff;
                  outline: 0;
                }
              }
            }
            .inputDiv + .inputDiv {
              margin-top: 10px;
            }
            .buttonDiv {
              width: 100%;
              text-align: center;
              margin-top: 20px;
              button {
                color: white;
                height: 35px;
                width: 100px;
                background: black;
                border: none;
                font-weight: 600;
                &:hover {
                  background: rgba(0, 0, 0, 0.877);
                }
              }
            }
          }
        }
      }
    }
  }
}
