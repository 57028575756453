.couponMain {
  h1 {
    font-size: 50px;
    margin-top: 50px;
    text-align: center;
    margin-bottom: 50px;
    @media screen and (max-width: 600px) {
        font-size: 40px;
    }
  }
  .couponsDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .coupon {
      width: 30%;
      height: 250px;
      background-size: cover;
      background-position: center;
      border-radius: 8px;
      box-shadow: rgb(179, 179, 179) 0px 2px 6px -1px;
      position: relative;
      @media screen and (max-width: 600px) {
          width: 100%;
          margin-bottom: 30px;
      }
      .bottomPortion {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        padding: 22px 12px 16px;
        color: white;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        background: rgba(0, 0, 0, 0.5);
        img {
          position: absolute;
          top: -52px;
          left: 12px;
          width: 80px;
          height: 60px;
          padding: 0px;
          border-radius: 7px;
          background: rgb(255, 255, 255);
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
        }
        label {
          margin: 0;
          font-size: 12px;
          line-height: 1.33;
          text-transform: uppercase;
          margin-bottom: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          letter-spacing: 0.6px;
          font-weight: bold;
        }
        p {
          margin: 0;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
