/*
CREDIT GOES TO: https://codepen.io/dsenneff/pen/QajVxO -->
i'm only forking to try and improe
*/
/* colors */
.body {
  background-color: red !important;
}

.login {
  .iti{
    width: 100%;
  }
  .error {
    color: red;
    text-align: left;
  }
  .page h2 {
    text-align: center;
    padding-top: 20px;
    /* background-image: url(./../Images/underline.png); */
    background-position: bottom;
    background-repeat: no-repeat;
    // padding-bottom: 30px;
    // margin-bottom: 20px;
    margin: 0 !important;
  }

  form .svgContainer {
    position: relative;
    width: 240px;
    height: 240px;
    margin: 0 auto 1em;
    background: none;
    overflow: hidden;
    pointer-events: none;
  }
  form .svgContainer div {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-bottom: 100%;
  }
  form .svgContainer .mySVG {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
  form .inputGroup {
    margin: 0 0 0.4em;
    padding: 0;
    position: relative;
  }
  form .inputGroup:last-of-type {
    margin-bottom: 0;
  }
  form label {
    // margin: 0 0 12px;
    margin-top: 20px;
    display: block;
    font-size: 1em;
    color: #333333;
    font-weight: bold;
    font-size: 16px;
    font-family: inherit;
  }
  .focusColor {
    input {
      border-color: #f47545 !important;
    }
    label {
      color: #f47545;
    }
  }
  form input[type="email"],
  form input[type="text"],
  form input[type="tel"],
  form input[type="password"],
  form input[type="number"] {
    display: block;
    margin: 0;
    // padding: 0 1em 0;
    background-color: white;
    border: none;
    border-bottom: solid 2px #838080;
    border-radius: 0px;
    -webkit-appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 35px;
    font-size: 18px;
    color: #353538;
    font-weight: 500;
    font-family: inherit;
    transition: box-shadow 0.2s linear, border-color 0.25s ease-out;
    font-size: 14px !important;
    padding: 6px 12px;
    min-height: 40px !important;
  }
  .btn-login {
    margin-top: 30px !important;
  }
  form input[type="email"]:focus,
  form input[type="text"]:focus,
  form input[type="password"]:focus {
    outline: none;
  }
  form input[type="email"],
  form input[type="text"] {
    // padding: 14px 1em 0px;
  }
  /*form button {
  display: block;
  margin: 0;
  padding: .65em 1em 1em;
  background-color: #4eb8dd;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: none;
  width: 100%;
  height: 65px;
  font-size: 1.55em;
  color: #FFF;
  font-weight: 600;
  font-family: inherit;
  transition: background-color .2s ease-out;
}*/
  form button:hover,
  form button:active {
    background-color: #ffaaaa;
  }
  form .inputGroup1 .helper {
    position: absolute;
    z-index: 1;
    font-family: inherit;
  }
  form .inputGroup1 .helper1 {
    top: 0;
    left: 0;
    -webkit-transform: translate(1.4em, 2.6em) scale(1);
    transform: translate(1.4em, 2.6em) scale(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    color: #ffaaaa;
    font-size: 1.25em;
    font-weight: 400;
    color: gray;
    opacity: 0.65;
    pointer-events: none;
    transition: opacity 0.2s linear, -webkit-transform 0.2s ease-out;
    transition: transform 0.2s ease-out, opacity 0.2s linear;
    transition: transform 0.2s ease-out, opacity 0.2s linear, -webkit-transform 0.2s ease-out;
  }
  form .inputGroup1.focusWithText .helper {
    /*input[type='email']:focus + .helper {*/
    -webkit-transform: translate(1.4em, 2em) scale(0.65);
    transform: translate(1.4em, 2em) scale(0.65);
    opacity: 1;
  }
}
.login {
  padding-top: 30px;
  padding-bottom: 30px;
  .mauve {
    fill: #4f4c9b;
  }
  .blank {
    fill: #ffffff;
  }
  .red {
    fill: #e83559;
  }
}
.resetPasForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  #username {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .iti {
    width: 100%;
  }
}
.marginBottom{
  margin-bottom: 20px !important;
}
